import * as React from "react";
import { Route } from "react-router";

import Layout from "@bokio/components/Layout/Layout";
import { useTopLevelUser } from "@bokio/contexts/TopLevelUserContext/useTopLevelUser";
import { Box } from "@bokio/elements/Box";
import { LoadingBox } from "@bokio/elements/Loading";
import { GeneralLangFactory } from "@bokio/lang";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { useSettings } from "@bokio/settings/src/scenes/Overview/components/utils/useSettings";
import {
	SupportMessageCompletedType,
	SupportMessageModal,
} from "@bokio/settings/src/scenes/SupportMessages/SupportMessageModal/SupportMessageModal";
import { useRouter } from "@bokio/shared/containers/router/useRouter";
import { getRoute } from "@bokio/shared/route";
import { useCompanyUser, useUser } from "@bokio/shared/state/requests";
import { hasFailedPaymentState } from "@bokio/utils/priceplanUtils";

import { AddFiscalYearModal } from "../AddFiscalYearModal/AddFiscalYearModal";
import { CompanyCommandPalette } from "../CommandPalette/CompanyCommandPalette";
import {
	menuSectionsToPaletteCommand,
	settingsSectionsToPaletteCommand,
} from "../CommandPalette/utils/commandPalette.helpers";
import { FailedPaymentTopBar } from "../FailedPaymentTopBar/FailedPaymentTopBar";
import CompanyMenuProvider from "../Menu/components/CompanyMenuProvider/CompanyMenuProvider";
import Sidebar from "../Sidebar/Sidebar";
import { SveaAuthenticationChecker } from "../SveaAuthenticationChecker/SveaAuthenticationChecker";
import { TestCompanyTopBar } from "../TestCompanyTopBar/TestCompanyTopBar";
import TopBar, { TopBarMode } from "../TopBar/TopBar";
import { CompanyAccessDeniedPage } from "./CompanyAccessDeniedPage/CompanyAccessDeniedPage";
import { SupportChat } from "./SupportChat";

import type { RouteProps } from "react-router";

import * as styles from "./companyRoute.scss";

import CompanySystem = m.Entities.CompanySystem;
import FiscalYearStatus = m.Core.FiscalYearStatus;
import SupportMessageType = m.Entities.Support.SupportMessageType;

type CompanyRouteProps = RouteProps;

const CompanyRouteContent = (props: CompanyRouteProps) => {
	const { companyInfo } = useCompanyInfo();
	const { userStatus, reloadCompanyUser } = useCompanyUser();
	const { isLoadingUser } = useUser();
	const router = useRouter();

	if (isLoadingUser || !userStatus || !companyInfo) {
		return (
			<>
				<Box>
					<LoadingBox spacingTop />
				</Box>
				<SveaAuthenticationChecker companyId={companyInfo.Id} />
			</>
		);
	}

	const isOnFiscalYearPage =
		router.location && router.location.pathname.startsWith(getRoute("fiscalList", { company: companyInfo.Id }));

	const freeUser = companyInfo.Plan == m.Entities.BokioPlan.Free;
	const blockedDueToFailedPayments =
		companyInfo.BillingPaymentState === m.Entities.BillingSubscriptionPaymentState.BlockedDueToMissingPayment;

	// AM 2024-12-03 If the user does not have company settings access but there is still an active year it should
	// still be possible to view content. For example, agencies should still be able to visit the client's pages.
	const needsFiscalUpdate =
		(userStatus.Access.CompanySettings && companyInfo.FiscalYearStatus === FiscalYearStatus.PromptToCreateNextYear) ||
		companyInfo.FiscalYearStatus === FiscalYearStatus.NoActiveYear;

	if (needsFiscalUpdate && !isOnFiscalYearPage && !freeUser && !blockedDueToFailedPayments) {
		const onFiscalYearCreated = () => reloadCompanyUser();

		return (
			<AddFiscalYearModal
				disableBackgroundClick={true}
				visible={true}
				disabled={true}
				onClose={onFiscalYearCreated}
				autoFocus={true}
				userPermissions={userStatus.Access}
			/>
		);
	}

	return <Route path={props.path} component={props.component} exact={props.exact} />;
};

const CompanyRouteLayout = (props: CompanyRouteProps & { isDemo: boolean }) => {
	const { companyInfo } = useCompanyInfo();
	const { companyUserPermissions } = useCompanyUser();
	const { settings } = useSettings(companyInfo, companyUserPermissions);
	const generalLang = GeneralLangFactory();
	const blockedDueToFailedPayment =
		companyInfo.BillingPaymentState === m.Entities.BillingSubscriptionPaymentState.BlockedDueToMissingPayment;
	return (
		<Layout
			isDemo={props.isDemo}
			menu={menuProps => (
				<div>
					<TopBar mode={TopBarMode.Company} toggleMenu={menuProps.toggleMenu} />
					<CompanyMenuProvider>
						{sections => (
							<>
								<Sidebar
									expanded={menuProps.expanded}
									onToggle={menuProps.toggleMenu}
									onClose={menuProps.closeMenu}
									sections={sections}
									showBookkeepingButton={companyInfo.Plan !== m.Entities.BokioPlan.Free && !blockedDueToFailedPayment}
								/>
								<CompanyCommandPalette
									commands={menuSectionsToPaletteCommand(generalLang, sections).concat(
										settingsSectionsToPaletteCommand(generalLang, settings),
									)}
								/>
							</>
						)}
					</CompanyMenuProvider>
				</div>
			)}
			content={<CompanyRouteContent {...props} />}
		/>
	);
};

const checkIfPromotionModal = (
	supportMessageToShow: m.Bokio.Common.Contract.ViewData.SupportMessageActiveDto | undefined,
): boolean => {
	if (supportMessageToShow === undefined) {
		return false;
	}

	const promotionModalTypes = [
		SupportMessageType.TrialToPaidPlan,
		SupportMessageType.FreeToPaidPlan,
		SupportMessageType.PremiumToBba,
		SupportMessageType.BbaToPremiumPlus,
	];

	return promotionModalTypes.includes(supportMessageToShow?.MessageType);
};

export const CompanyRoute = (props: CompanyRouteProps) => {
	const { companyInfo } = useCompanyInfo();
	const { redirect } = useRouter();
	const { hasErrorGettingCompanyUser } = useTopLevelUser();

	const [refreshBlockingSupportMessages, blockingSupportMessagesRequest] = useLazyApi(
		proxy.Settings.SupportMessagesController.Index.Get,
	);

	React.useEffect(() => {
		//ME: With this approach the support messages doesn't reload when you navigate between different company routes.
		if (companyInfo !== undefined) {
			refreshBlockingSupportMessages(companyInfo.Id, true);
		}
	}, [companyInfo, refreshBlockingSupportMessages]);

	if (hasErrorGettingCompanyUser) {
		return <CompanyAccessDeniedPage />;
	}

	// Do not render anything if company hasn't loaded. If we don't do this we will get problems
	// in menus because they are mounted twice
	if (!companyInfo) {
		return null;
	}

	const isDemo = companyInfo.CompanySystem === CompanySystem.DemoCompany;
	const isTest = companyInfo.CompanySystem === CompanySystem.TestCompany;
	const hasFailedPayment = hasFailedPaymentState(companyInfo.BillingPaymentState);

	const supportMessageToShow = !blockingSupportMessagesRequest.isLoading
		? blockingSupportMessagesRequest.data?.Data?.[0]
		: undefined;

	const isPromotionModal = checkIfPromotionModal(supportMessageToShow);

	const getPromotionModalRedirect = (SupportMessageType: m.Entities.Support.SupportMessageType): string => {
		switch (SupportMessageType) {
			case m.Entities.Support.SupportMessageType.PremiumToBba:
				return getRoute("bankList", {
					company: companyInfo.Id,
				});

			default:
				return getRoute("billing", {
					company: companyInfo.Id,
				});
		}
	};

	const onComplete = async (type?: SupportMessageCompletedType) => {
		await refreshBlockingSupportMessages(companyInfo.Id, true);

		if (!!supportMessageToShow && isPromotionModal && type === SupportMessageCompletedType.Resolved) {
			const redirectTo = getPromotionModalRedirect(supportMessageToShow.MessageType);

			redirect(redirectTo);
		}
	};

	if (!isDemo && !isTest && !hasFailedPayment) {
		return (
			<>
				<CompanyRouteLayout {...props} isDemo={false} />
				<SupportChat />
				{!!supportMessageToShow && (
					<SupportMessageModal
						supportMessage={supportMessageToShow}
						onComplete={onComplete}
						companyId={companyInfo.Id}
					/>
				)}
			</>
		);
	}

	return (
		<div className={styles.companyRouteWrapper}>
			{isTest && <TestCompanyTopBar />}
			{hasFailedPayment && <FailedPaymentTopBar company={companyInfo.Id} />}
			<CompanyRouteLayout {...props} isDemo={true} />
			<SupportChat />
			{!!supportMessageToShow && (
				<SupportMessageModal supportMessage={supportMessageToShow} onComplete={onComplete} companyId={companyInfo.Id} />
			)}
		</div>
	);
};
