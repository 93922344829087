import * as React from "react";

import { createEventHub } from "@bokio/shared/services/api/signalRHub";

import type * as m from "@bokio/mobile-web-shared/core/model/model";

type EmailActivityDto = m.Core.ViewData.EmailActivityDto;
export interface EmailDeliveryState {
	emailActivities: EmailActivityDto[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/no-empty-object-type
export interface EmailDeliveryProps extends EmailDeliveryState {}

const defaultContextValue: EmailDeliveryState = {
	emailActivities: [],
};

const EmailDeliveryContext = React.createContext(defaultContextValue);

export default EmailDeliveryContext;

export const emailActivityHub = createEventHub<EmailActivityDto>();
