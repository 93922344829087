import * as React from "react";

import DeviceQuery from "@bokio/elements/DeviceQuery/DeviceQuery";
import { Link } from "@bokio/elements/Link/Link";
import * as styles from "@bokio/elements/Sorter/sorterHeadingMobile.scss";
import GeneralLangFactory from "@bokio/lang/GeneralLangFactory";
import { mergeClassNames } from "@bokio/utils/classes";

import Icon from "../Icon/Icon";
import { MobileOverlay } from "../MobileOverlay/MobileOverlay";

import type { SorterConfig } from "./Sorter";

interface SorterHeadingMobileProps<T> {
	sortItems: SorterItemsMobileConfig[];
	config: SorterConfig<T>;
	onUpdate: (newConfig: SorterConfig<T>) => void;
}

interface SorderHeadingMobileState {
	showSort: boolean;
}

export interface SorterItemsMobileConfig {
	sortKey: string;
	sortLabel: string;
	active: boolean;
}

class SorterHeadingMobile<T> extends React.Component<SorterHeadingMobileProps<T>> {
	state: SorderHeadingMobileState = {
		showSort: false,
	};

	render() {
		const { config, sortItems } = this.props;
		const { showSort } = this.state;
		const lang = GeneralLangFactory();
		const activeItem = sortItems.filter(item => item.active)[0].sortLabel;

		const activeOrderIcon = config.sortOrder == "asc" ? "ascending" : "descending";
		return (
			<DeviceQuery>
				{({ isMobile }) =>
					isMobile && (
						<React.Fragment>
							<Link style="none" className={styles.sortByLabel} onClick={this.toggleSort}>
								{lang.SortBy}: {activeItem} <Icon name={activeOrderIcon} />
							</Link>
							<MobileOverlay onClose={this.toggleSort} visible={showSort} heading={lang.Sort}>
								<div className={styles.sortGroup}>
									<p className={styles.title}>{lang.Order_label}</p>
									<Link
										style="none"
										className={mergeClassNames(styles.sortItem, config.sortOrder === "desc" && styles.active)}
										onClick={() => this.handleOrderChange("desc")}
									>
										{lang.Descending_label}
									</Link>
									<Link
										style="none"
										className={mergeClassNames(styles.sortItem, config.sortOrder === "asc" && styles.active)}
										onClick={() => this.handleOrderChange("asc")}
									>
										{lang.Ascending_label}
									</Link>
								</div>
								<div>
									<p className={styles.title}>{lang.SortBy}</p>
									{sortItems.map((item, i) => (
										<Link
											style="none"
											key={i}
											className={mergeClassNames(styles.sortItem, item.active && styles.active)}
											onClick={() => this.handleSortChange(item.sortKey)}
										>
											{item.sortLabel}
										</Link>
									))}
								</div>
							</MobileOverlay>
						</React.Fragment>
					)
				}
			</DeviceQuery>
		);
	}

	toggleSort = () => this.setState({ showSort: !this.state.showSort });

	handleOrderChange = (order: "asc" | "desc") => {
		const { config } = this.props;
		this.props.onUpdate({ ...config, sortOrder: order, sortProperty: config.sortProperty });
		this.toggleSort();
	};

	handleSortChange = (sortKey: string) => {
		const { config } = this.props;
		this.props.onUpdate({ ...config, sortOrder: config.sortOrder, sortProperty: sortKey });
		this.toggleSort();
	};
}

export default SorterHeadingMobile;
