import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { useCompanyUser } from "@bokio/shared/state/requests";

import { useLazyApi } from "../useApi/useApi";

export const useUserFeedback = (
	onSuccess?: (
		data: m.Settings.Controllers.HelpCreateResult,
		params: [string, m.Contracts.HelpRequest, (Blob | string)[]],
	) => void,
) => {
	const [sendFeedbackApi, sendFeedbackRequest] = useLazyApi(proxy.Settings.HelpController.Create.Post, { onSuccess });
	const [saveFeedback] = useLazyApi(proxy.Bokio.Common.Web.Metrics.MetricsController.SaveGenericFeedback.Post);
	const { companyInfo } = useCompanyInfo();
	const { userStatus } = useCompanyUser();

	const sendFeedback = (message: string, subject: string, topic: string) => {
		saveFeedback(companyInfo.Id, { Topic: topic, Subject: subject, Message: message });
		sendFeedbackApi(
			companyInfo.Id,
			{
				CurrentPage: window.location.href,
				Email: userStatus?.Email ?? "",
				Name: userStatus?.Name ?? "",
				Message: message,
				Subject: subject,
				Type: m.Contracts.HelpType.Feedback,
				Area: m.Contracts.SupportFormArea.Other,
				Category: m.Contracts.SupportFormCategory.NotSet,
				SubCategory: m.Contracts.SupportFormSubCategory.NotSet,
				ReferenceId: "",
				PhoneNumber: "",
			},
			[],
		);
	};

	return { sendFeedback, sendFeedbackRequest };
};
