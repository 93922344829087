// extracted by css-extract-rspack-plugin
var _1 = "N7a";
var _2 = "Db";
var _3 = "O7a";
var _4 = "Hb";
var _5 = "P7a";
var _6 = "_2mfBDlQ7l2GRI09V-82ngF";
var _7 = "T7a";
var _8 = "M7a";
var _9 = "Q7a";
var _a = "R7a";
var _b = "S7a";
var _c = "Tb";
export { _1 as "border", _2 as "colorError", _3 as "colorHighlight", _4 as "colorOk", _5 as "colorSubdued", _6 as "colorWarning", _7 as "isAnimating", _8 as "root", _9 as "sizeNormal", _a as "sizeSmall", _b as "sizeXsmall", _c as "zoomin" }
