/**
 * ┌────────────────────────────────────────────────────────────────────────┐
 * │ This file has been generated by routeGenerator                         │
 * │                                                                        │
 * │ The routes are defined in bundles.ts                                   │
 * │ Some routes may require changes in Rewrite.cs                          │
 * │ Please do not edit this file manually.                                 │
 * └────────────────────────────────────────────────────────────────────────┘
**/
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/ban-ts-comment */
import * as React from "react";
import { Route } from "react-router";

import { AgencyRoute } from "@bokio/components/Route/AgencyRoute";
import { CompanyRoute } from "@bokio/components/Route/CompanyRoute";
import { GuestRoute } from "@bokio/components/Route/GuestRoute";
import UserRoute from "@bokio/components/Route/UserRoute";
import { routes } from "@bokio/shared/route";
import createBundleLoader from "@bokio/utils/createBundleLoader";
import { ServerRouteRedirect } from "@bokio/utils/ServerRouteRedirect";

// Keep the imports just so they don't get removed
createBundleLoader;
ServerRouteRedirect;
Route;
routes;
GuestRoute;
UserRoute;
CompanyRoute;
AgencyRoute;

const scenes = {

  /* Signup */
  signUp: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "sign-up" */ "@bokio/guest/src/scenes/SignUp/SignUpScene");
    },
    narrowPage: true
  }),
  signupStart: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "sign-up" */ "@bokio/guest/src/scenes/SignUp/SignUpScene");
    },
    narrowPage: true
  }),
  signupOrLogin: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "sign-up" */ "@bokio/guest/src/scenes/SignUp/SignUpScene");
    },
    narrowPage: true
  }),
  signupBankIdEmail: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "sign-up" */ "@bokio/guest/src/scenes/SignUp/SignUpScene");
    },
    narrowPage: true
  }),
  signupMultipleAccounts: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "sign-up" */ "@bokio/guest/src/scenes/SignUp/SignUpScene");
    },
    narrowPage: true
  }),
  signUpAccountant: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "sign-up" */ "@bokio/guest/src/scenes/SignUp/SignUpScene");
    },
    narrowPage: true
  }),
  login: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "sign-up" */ "@bokio/guest/src/scenes/Login/LoginScene");
    },
    narrowPage: true
  }),
  connectAgency: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "sign-up" */ "@bokio/guest/src/scenes/Login/LoginScene");
    },
    narrowPage: true
  }),
  forgotPassword: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "sign-up" */ "@bokio/guest/src/scenes/ForgotPassword/ForgotPasswordScene");
    },
    narrowPage: true
  }),
  resetPassword: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "sign-up" */ "@bokio/guest/src/scenes/ResetPassword/ResetPasswordScene");
    },
    narrowPage: true
  }),
  acceptInvitation: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "sign-up" */ "@bokio/guest/src/scenes/AcceptInvitation/AcceptInvitationScene");
    },
    narrowPage: true
  }),
  acceptCompanyOwnerInvitation: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "sign-up" */ "@bokio/guest/src/scenes/AcceptInvitation/AcceptCompanyOwnerInvitationScene");
    },
    narrowPage: true
  }),
  confirmEmail: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "sign-up" */ "@bokio/guest/src/scenes/ConfirmEmail/ConfirmEmailScene");
    },
    narrowPage: true
  }),
  integrationTests: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "sign-up" */ "@bokio/guest/src/scenes/IntegrationTests/IntegrationTestsScene");
    },
    narrowPage: true
  }),
  voucherFlow: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "sign-up" */ "@bokio/guest/src/scenes/Voucher/VoucherFlowScene");
    },
    narrowPage: true
  }),
  /* Settings */
  createCompany: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/guest/src/scenes/SignUp/CreateCompanyScene");
    },
    narrowPage: true
  }),
  preCreateClient: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/guest/src/scenes/SignUp/CreateClientPreScene");
    },
    narrowPage: true
  }),
  settingsImportCompany: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/ImportBookkeeping/ImportBookkeepingScene");
    },
  }),
  settingsImportOverview: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/ImportPreviousBookkeeping/ImportPreviousBookkeepingScene");
    },
  }),
  fiscalList: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/FiscalYears/FiscalYearsListScene");
    },
  }),
  fiscalEdit: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/FiscalYears/FiscalYearEditScene");
    },
  }),
  fiscalCreate: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/FiscalYears/FiscalYearCreateScene");
    },
  }),
  accountPlan: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/AccountPlan/AccountPlanScene");
    },
  }),
  mapAccounts: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/MapAccounts/MapAccountsScene");
    },
  }),
  invoiceSettings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/InvoiceSettings/InvoiceSettingsScene");
    },
  }),
  eInvoiceSettings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/EInvoiceSettings/EInvoiceSettingsScene");
    },
  }),
  automaticCheckingSettings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/AutomaticCheckingSettings/AutomaticCheckingSettingsScene");
    },
  }),
  paymentMethodSettings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/PaymentMethodSettings/PaymentMethodSettingsScene");
    },
  }),
  settingsOverview: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/Overview/SettingsOverviewScene");
    },
  }),
  personalSettings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/PersonalSettings/PersonalSettingsScene");
    },
  }),
  companyDataStorage: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/CompanyDataStorage/CompanyDataStorageScene");
    },
  }),
  totpActivate: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/TwoFactor/TOTPSettings/TOTPActivateScene");
    },
  }),
  totpDeactivate: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/TwoFactor/TOTPSettings/TOTPDeactivateScene");
    },
  }),
  totpSettings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/TwoFactor/TOTPSettings/TOTPSettingsScene");
    },
  }),
  totpRecoveryDocument: ServerRouteRedirect,
  recentLogins: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/RecentLogins/RecentLoginsScene");
    },
  }),
  logOutEverywhere: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/LogOutEverywhere/LogOutEverywhereScene");
    },
  }),
  bankidSettings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/BankIdSettings/BankIdSettingsScene");
    },
  }),
  bankidActivate: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/BankIdSettings/BankIdActivateScene");
    },
  }),
  bankidDeactivate: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/BankIdSettings/BankIdDeactivateScene");
    },
  }),
  bankIdRequired: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/BankIdLogin/BankIdRequiredScene");
    },
  }),
  updateUsername: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/UpdateUsername/UpdateUsernameScene");
    },
  }),
  confirmUpdateUsername: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/UpdateUsernameConfirm/UpdateUsernameConfirmScene");
    },
  }),
  moneyAccounts: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/MoneyAccounts/MoneyAccountsScene");
    },
  }),
  tagTypes: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/TagTypes/TagTypesScene");
    },
  }),
  exportBookkeeping: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/ExportBookkeeping/ExportBookkeepingScene");
    },
  }),
  openingBalances: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/OpeningBalances/OpeningBalancesScene");
    },
  }),
  accountPayables: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/AccountPayables/AccountPayablesScene");
    },
  }),
  accountReceivables: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/AccountReceivables/AccountReceivablesScene");
    },
  }),
  layoutLogoColor: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/LayoutLogoColor/LayoutLogoColorScene");
    },
  }),
  companySettings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/CompanySettings/CompanySettingsScene");
    },
  }),
  changeCompanySystem: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/ChangeCompanySystem/ChangeCompanySystemScene");
    },
  }),
  deleteCompany: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/DeleteCompany/DeleteCompanyScene");
    },
  }),
  leaveCompany: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/LeaveCompany/LeaveCompanyScene");
    },
  }),
  deleteUserAccount: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/DeleteUserAccount/DeleteUserAccountScene");
    },
  }),
  featureToggles: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/FeatureToggles/FeatureTogglesScene");
    },
  }),
  agreements: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/Legal/CurrentAgreementsScene");
    },
  }),
  signTerms: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/Legal/SignTermsScene");
    },
  }),
  settings: ServerRouteRedirect,
  companySetting: ServerRouteRedirect,
  inbox: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/Inbox/InboxScene");
    },
  }),
  inboxHistory: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/Inbox/InboxHistory/InboxHistoryScene");
    },
  }),
  inboxAttachmentDownload: ServerRouteRedirect,
  exportData: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/ExportDataScene/ExportDataScene");
    },
  }),
  users: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/Users/UsersScene");
    },
  }),
  changePassword: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/ChangePassword/ChangePasswordScene");
    },
  }),
  auditHistory: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/AuditHistory/AuditHistoryScene");
    },
  }),
  exportAuditHistory: ServerRouteRedirect,
  companyVacationSettings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/CompanyVacationSettings/CompanyVacationSettingsScene");
    },
  }),
  placeOfEmploymentSettings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/PlaceOfEmploymentSettings/PlaceOfEmploymentSettingsScene");
    },
  }),
  salarySettingUK: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/SalarySettingsUK/SalarySettingsUKScene");
    },
  }),
  activateVoucher: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/Billing/ActivateVoucherScene");
    },
  }),
  billing: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/Billing/BillingScene");
    },
  }),
  viewBill: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/Billing/ViewBillScene");
    },
  }),
  downloadBillingReceiptPdf: ServerRouteRedirect,
  cardList: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/Cards/CardListScene");
    },
  }),
  card: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/Cards/CardScene");
    },
  }),
  orderCard: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/Cards/OrderCardScene");
    },
  }),
  supportMessages: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/SupportMessages/SupportMessageScene");
    },
  }),
  bokioBusinessAccountOffboarding: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/BokioBusinessAccountOffboarding/BokioBusinessAccountOffboardingScene");
    },
  }),
  sveaBankConsent: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/Svea/SveaConsentScene");
    },
  }),
  sveaKycScene: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/SveaKyc/SveaKycApplicationScene");
    },
  }),
  notificationSettings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/NotificationSettings/NotificationSettingsScene");
    },
  }),
  notificationRedirect: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/NotificationRedirect/NotificationRedirectScene");
    },
  }),
  exportSIEFile: ServerRouteRedirect,
  customTemplates: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/CustomTemplates/CustomTemplatesScene");
    },
  }),
  automationRules: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/AutomationRules/AutomationRulesScene");
    },
  }),
  mirChangeSignatory: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/bank/src/scenes/MirChangeSignatory/MirChangeSignatoryScene");
    },
  }),
  removeImport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "settings" */ "@bokio/settings/src/scenes/RemoveImport/RemoveImportScene");
    },
  }),
  /* Bookkeeping */
  hmrcAuth: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "bookkeep" */ "@bokio/web/scenes/HMRC/HMRCAuthScene");
    },
  }),
  accounting: ServerRouteRedirect,
  preBookkeep: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "bookkeep" */ "@bokio/bookkeeping/src/scenes/PreBookkeep/PreBookkeepScene");
    },
  }),
  bookkeepReceipt: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "bookkeep" */ "@bokio/bookkeeping/src/scenes/Bookkeep/BookkeepScene");
    },
  }),
  recordingCheckList: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "bookkeep" */ "@bokio/reports/src/scenes/RecordingCheck/RecordingCheckList");
    },
  }),
  recordingCheckCreate: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "bookkeep" */ "@bokio/reports/src/scenes/RecordingCheck/CreateRecordingCheckScene");
    },
  }),
  recordingCheckView: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "bookkeep" */ "@bokio/reports/src/scenes/RecordingCheck/RecordingCheckViewScene");
    },
  }),
  bookkeepExpense: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "bookkeep" */ "@bokio/bookkeeping/src/scenes/Bookkeep/BookkeepExpenseScene");
    },
  }),
  bookkeepSupplier: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "bookkeep" */ "@bokio/bookkeeping/src/scenes/Bookkeep/BookkeepSupplierInvoiceScene");
    },
  }),
  bookkeepTransaction: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "bookkeep" */ "@bokio/bookkeeping/src/scenes/Bookkeep/BookkeepAutoTransactionScene");
    },
  }),
  editVerification: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "bookkeep" */ "@bokio/bookkeeping/src/scenes/EditVerification/EditVerificationScene");
    },
  }),
  attest: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "bookkeep" */ "@bokio/bookkeeping/src/scenes/Attest/AttestScene");
    },
  }),
  eventsToRecord: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "bookkeep" */ "@bokio/bookkeeping/src/scenes/EventsToRecord/EventsToRecordScene");
    },
  }),
  receiptImage: ServerRouteRedirect,
  downloadReceipt: ServerRouteRedirect,
  receiptThumbnail: ServerRouteRedirect,
  receiptThumbnailAlt: ServerRouteRedirect,
  receiptExtraImage: ServerRouteRedirect,
  loginToArsRedovisningOnline: ServerRouteRedirect,
  importSi: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "bookkeep" */ "@bokio/bookkeeping/src/scenes/ImportSi/ImportSiScene");
    },
  }),
  /* Bank */
  bankImport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/ImportFromBank/ImportFromBankScene");
    },
  }),
  bankList: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/BankScene");
    },
  }),
  bokioBusinessAccountMigration: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/BokioBusinessAccountMigrationScene/BokioBusinessAccountMigrationScene");
    },
  }),
  sveaOnboarding: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/Svea/Onboarding/SveaOnboardingScene");
    },
  }),
  sveaOnboardingAgreementPdf: ServerRouteRedirect,
  sveaAddInternationalPaymentsScene: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/Svea/Onboarding/SveaAddInternationalPaymentsScene");
    },
  }),
  sveaInternationalPayments: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/Svea/Preregistrations/SveaPreregistrationListScene");
    },
  }),
  sveaPreregistrationViewScene: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/Svea/Preregistrations/SveaPreregistrationViewScene");
    },
  }),
  sveaMatchingScene: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/Svea/PaymentMatching/SveaPaymentMatchingScene");
    },
  }),
  sveaAddSavingsAccount: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/Svea/Onboarding/SveaAddSavingsAccountScene");
    },
  }),
  sveaSwishOverview: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/Svea/Swish/SwishOverviewScene");
    },
  }),
  sveaSwishOnboarding: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/Svea/Swish/SwishOnboardingScene");
    },
  }),
  sveaInternationalPaymentsPreregistrations: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/Svea/InternationalPaymentsV2/SveaInternationalPaymentsScene");
    },
  }),
  sveaInternationalPaymentsPreregistrationDetails: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/Svea/InternationalPaymentsV2/Preregistrations/SveaInternationalPaymentsPreregistrationDetailsScene");
    },
  }),
  bankManage: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/ManageBankConnection/ManageBankConnectionScene");
    },
  }),
  bankAccountEdit: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/BankAccountEditImports/BankAccountEditImportsScene");
    },
  }),
  bankAccount: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/BankAccount/BankAccountScene");
    },
  }),
  bankInbox: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/Svea/Inbox/SveaInboxScene");
    },
  }),
  sveaInboxAttachmentFile: ServerRouteRedirect,
  bankAccountQrCode: ServerRouteRedirect,
  bankPayments: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/PaymentsCentral/PaymentsCentralScene");
    },
  }),
  bankCreatePayment: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/CreatePayment/CreatePaymentScene");
    },
  }),
  bankCreatePaymentSuccess: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/CreatePaymentSuccess/CreatePaymentSuccessScene");
    },
  }),
  bokioBusinessAccountOnboardingSteps: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/MirOnboardingSteps/MirOnboardingStepsScene");
    },
  }),
  mirOnboardingAgreementPdf: ServerRouteRedirect,
  bankStatementPdf: ServerRouteRedirect,
  transactionDetailsPdf: ServerRouteRedirect,
  blockedBankList: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "banks" */ "@bokio/bank/src/scenes/BlockedBankScene/BlockedBankScene");
    },
  }),
  /* Supplier invoices */
  suppliersInvoicesAll: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "suppliers" */ "@bokio/suppliers/src/scenes/AllSupplierInvoices/AllSupplierInvoicesScene");
    },
  }),
  supplierInvoices: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "suppliers" */ "@bokio/suppliers/src/scenes/SupplierInvoiceList/SupplierInvoiceListScene");
    },
  }),
  supplierInvoice: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "suppliers" */ "@bokio/suppliers/src/scenes/SupplierInvoice/SupplierInvoiceScene");
    },
  }),
  suppliers: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "suppliers" */ "@bokio/suppliers/src/scenes/SupplierList/SupplierListScene");
    },
  }),
  suppliersImport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "suppliers" */ "@bokio/suppliers/src/scenes/SuppliersImport/SuppliersImport");
    },
  }),
  supplierInvoiceQrCode: ServerRouteRedirect,
  /* Customer invoices */
  periodicReport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/Reports/PeriodicReport/PeriodicReportScene");
    },
  }),
  invoicesShow: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/InvoiceShow/InvoiceShow");
    },
  }),
  invoicesCreateOrEdit: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/CreateOrEditInvoice/CreateOrEditInvoiceScene");
    },
  }),
  invoices: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/Invoices/Invoices");
    },
  }),
  articles: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/Articles/ArticlesScene");
    },
  }),
  articlesImport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/ArticlesImport/ArticlesImport");
    },
  }),
  rotRutErrands: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/RotRutErrands/RotRutErrands");
    },
  }),
  logoImageUrl: ServerRouteRedirect,
  factoringActivation: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/FactoringOnboarding/FactoringOnboardingScene");
    },
  }),
  exportArticles: ServerRouteRedirect,
  customerDownload: ServerRouteRedirect,
  invoiceLatestPdf: ServerRouteRedirect,
  invoicePdf: ServerRouteRedirect,
  rotXML: ServerRouteRedirect,
  rutXML: ServerRouteRedirect,
  supplierInvoiceImage: ServerRouteRedirect,
  exportInvoices: ServerRouteRedirect,
  customersImport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/CustomersImport/CustomersImport");
    },
  }),
  customers: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/Customers/CustomersScene");
    },
  }),
  customerInvoices: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/Customers/CustomerInvoiceListScene");
    },
  }),
  exportCustomers: ServerRouteRedirect,
  exportAccountsReceivable: ServerRouteRedirect,
  exportAccountsPayable: ServerRouteRedirect,
  invoiceAttachment: ServerRouteRedirect,
  quotesCreateEdit: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/QuoteCreateEdit/QuoteCreateEditScene");
    },
  }),
  quoteDetails: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/QuoteDetails/QuoteDetailsScene");
    },
  }),
  quotes: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/Quotes/QuotesScene");
    },
  }),
  quotePdf: ServerRouteRedirect,
  recurringInvoiceDetailsOverview: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/RecurringInvoices/components/RecurringInvoicesDetailsScene/RecurringInvoicesDetailsOverviewScene");
    },
  }),
  recurringInvoices: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "invoices" */ "@bokio/invoices/src/scenes/RecurringInvoices/RecurringInvoicesScene");
    },
  }),
  /* Expenses */
  expenses: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "expenses" */ "@bokio/expenses/src/scenes/MyExpensesScene/MyExpensesScene");
    },
  }),
  expensesUpload: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "expenses" */ "@bokio/expenses/src/scenes/CreateExpense/UploadExpense/UploadExpenseScene");
    },
  }),
  expensesUploadShow: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "expenses" */ "@bokio/expenses/src/scenes/CreateExpense/EditExpense/EditExpenseScene");
    },
  }),
  expenseReceiptView: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "expenses" */ "@bokio/expenses/src/scenes/ExpenseReceiptViewScene");
    },
  }),
  expensesPaymentsList: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "expenses" */ "@bokio/expenses/src/scenes/ExpensePayments/AllExpensePaymentsList");
    },
  }),
  expensesPayments: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "expenses" */ "@bokio/expenses/src/scenes/ExpensePayments/ExpensePayments");
    },
  }),
  showExpensePayment: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "expenses" */ "@bokio/expenses/src/scenes/ShowPayment/ShowPaymentScene");
    },
  }),
  expensesPermissions: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "expenses" */ "@bokio/expenses/src/scenes/ConnectEmployees");
    },
  }),
  expensesOverview: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "expenses" */ "@bokio/expenses/src/scenes/AllExpenses");
    },
  }),
  expenseSettings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "expenses" */ "@bokio/expenses/src/scenes/ExpenseSettings/ExpenseSettingsScene");
    },
  }),
  myExpenseAccounts: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "expenses" */ "@bokio/expenses/src/scenes/ExpenseAccounts/ExpenseAccountsScene");
    },
  }),
  companyCards: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "expenses" */ "@bokio/expenses/src/scenes/CompanyCards/CompanyCardsScene");
    },
  }),
  convertToExpenses: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "expenses" */ "@bokio/expenses/src/scenes/ConvertToExpense/ConvertToExpenseScene");
    },
  }),
  /* Salary */
  salarySettings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "salaries" */ "@bokio/salaries/src/scenes/SalarySettings/SalarySettingsScene");
    },
  }),
  salaryPayrollPayslipShow: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "salaries" */ "@bokio/web/scenes/Payslip/Payslip");
    },
  }),
  salaryPayrollTaxreport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "salaries" */ "@bokio/salaries/src/scenes/TaxReport/TaxReport");
    },
  }),
  salaryTaxreportRedirect: ServerRouteRedirect,
  myPayslipPdf: ServerRouteRedirect,
  bulkpayslipPdf: ServerRouteRedirect,
  p60Pdf: ServerRouteRedirect,
  P45Pdf: ServerRouteRedirect,
  salary2Payroll_old: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "salaries" */ "@bokio/salaries/src/scenes/Salary2/Payroll/PayrollScene/PayrollScene");
    },
  }),
  salaryPayrollDetails: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "salaries" */ "@bokio/salaries/src/scenes/Salary2/Payroll/PayrollDetails");
    },
  }),
  salaryAGIdownload: ServerRouteRedirect,
  salaryTaxPeriodList: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "salaries" */ "@bokio/salaries/src/scenes/Salary2/Payroll/SalaryTaxPeriods/SalaryTaxPeriodListScene");
    },
  }),
  salaryTaxPeriodView: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "salaries" */ "@bokio/salaries/src/scenes/Salary2/Payroll/SalaryTaxPeriods/SalaryTaxPeriodViewScene");
    },
  }),
  /* Salary/Absence */
  salaryAbsense: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "absence" */ "@bokio/salaries/src/scenes/Salary2/Absence/AbsenceScene/AbsenceScene");
    },
  }),
  salaryAGI: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "absence" */ "@bokio/salaries/src/scenes/Reports/AGI/AGIScene");
    },
  }),
  /* Reports */
  balanceReport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/BalanceReport/BalanceReportScene");
    },
  }),
  balanceReportAccountPage: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/BalanceReport/pages/BalanceReportAccountPage/BalanceReportAccountPage");
    },
  }),
  vatList: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/VatReport/VatList/VatListScene");
    },
  }),
  vatReport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/VatReportSceneSE/VatReportSceneSE");
    },
  }),
  vatReport2: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/VatReportSceneSE/VatReportSceneSE2");
    },
  }),
  vatReportHmrc: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/VatReport/VatReportHmrcScene");
    },
  }),
  vatCheck: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/VatReport/VatCheck/VatCheckScene");
    },
  }),
  hmrcVatCheck: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/VatReport/VatCheck/HmrcVatCheckScene");
    },
  }),
  resultReport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/ResultReport/ResultReportScene");
    },
  }),
  tagReport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/TagReport/TagReportScene");
    },
  }),
  drillDownReport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/DrilldownReport/DrilldownReportScene");
    },
  }),
  resultReportAccountPage: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/ResultReport/pages/ResultReportAccountPage/ResultReportAccountPage");
    },
  }),
  ledgerReconcilliation: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/AccountingCheck/LedgerReconcilliation/LedgerReconcilliationScene");
    },
  }),
  ledgerReport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/Ledger/LedgerScene");
    },
  }),
  accountedList: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/Accounted/AccountedScene");
    },
  }),
  accountingCheckShow: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/AccountingCheck/AccountingCheckShow/AccountingCheckShowScene");
    },
  }),
  checkVerifications: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/AccountingCheck/CheckVerifications/CheckVerificationsScene");
    },
  }),
  vatReportXmlDownload: ServerRouteRedirect,
  balanceReportPdf: ServerRouteRedirect,
  balanceReportExcel: ServerRouteRedirect,
  resultReportPdf: ServerRouteRedirect,
  resultReportExcel: ServerRouteRedirect,
  ledgerPdf: ServerRouteRedirect,
  trialBalanceExcel: ServerRouteRedirect,
  ledgerAccountExcel: ServerRouteRedirect,
  invoicesReport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/InvoicesReport/InvoicesReportScene");
    },
  }),
  salaryReports: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/SalaryReport/SalaryReports/SalaryReportsScene");
    },
  }),
  salaryReportSalaryTotal: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/SalaryReport/SalaryReport/SalaryTotal/SalaryTotalScene");
    },
  }),
  salaryReportVacationSummary: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/SalaryReport/SalaryReport/VacationSummary/VacationSummaryScene");
    },
  }),
  salaryReportSickDays: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/SalaryReport/SalaryReport/SickDays/SickDaysScene");
    },
  }),
  salaryReportHourlyPaid: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/SalaryReport/SalaryReport/HourlyPaid/HourlyPaidScene");
    },
  }),
  salaryReportVacationDebt: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/SalaryReport/SalaryReport/VacationDebtReport/VacationDebtReportScene");
    },
  }),
  agedDebtorsReport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/AgedDebtorsReport/AgedDebtorsReportScene");
    },
  }),
  agedDebtorsReportPdf: ServerRouteRedirect,
  agedCreditorsReport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "reports" */ "@bokio/reports/src/scenes/AgedCreditorsReport/AgedCreditorsReportScene");
    },
  }),
  accountPayablePdf: ServerRouteRedirect,
  /* EmployeesWithContracts */
  employeeList: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/EmployeeList/EmployeeListScene");
    },
  }),
  employeeAdd: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/AddEmployee/AddEmployeeScene");
    },
  }),
  manageActiveEmployees: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/ManageActiveEmployees/ManageActiveEmployeesScene");
    },
  }),
  employeeDetails: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/AddEmployee/EmployeeDetailsScene");
    },
  }),
  employeeVacationTrackingForm: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/AddEmployee/VacationSettingsScene");
    },
  }),
  employeeVacationForm: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/AddEmployee/VacationSettingsForm");
    },
  }),
  vacationTracking: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/VacationTracking/VacationTrackingScene");
    },
  }),
  vacationTrackingSetup: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/VacationTrackingSetup/SetupVacationTrackingScene");
    },
  }),
  employeeView: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/EmployeeView/EmployeeViewScene");
    },
  }),
  editDirector: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/EditDirector/EditDirectorScene");
    },
  }),
  contractView: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/ContractView/ContractViewScene");
    },
  }),
  contractAdd: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/ContractAddForm/ContractAddFormScene");
    },
  }),
  contractEdit: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/ContractEditForm/ContractEditFormScene");
    },
  }),
  myContractView: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/ContractView/MyContractViewScene");
    },
  }),
  myEmployee: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/MyEmployeeView/MyEmployeeViewScene");
    },
  }),
  myEmployeeSalary: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/MyEmployeeView/MyEmployeeViewScene");
    },
  }),
  employeesImport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/EmployeeImport/EmployeesImport");
    },
  }),
  exportEmployees: ServerRouteRedirect,
  employeeDownload: ServerRouteRedirect,
  vacationYearEnd: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/VacationYearEnd/VacationYearEndScene");
    },
  }),
  vacationYearEndEmployeeDetail: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "employeeswithcontracts" */ "@bokio/employeeswithcontracts/src/scenes/VacationYearEnd/VacationYearEndEmployeeDetailScene");
    },
  }),
  GetMyP45: ServerRouteRedirect,
  GetMyP60: ServerRouteRedirect,
  /* Development */
  developerTools: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "dev" */ "@bokio/devtools/src/scenes/DeveloperToolsScene");
    },
  }),
  mockTools: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "dev" */ "@bokio/devtools/src/scenes/StagingMockToolsScene");
    },
  }),
  stagingEnableBankingScene: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "dev" */ "@bokio/devtools/src/scenes/StagingEnableBankingScene");
    },
  }),
  /* Closures */
  annualReportForm: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "closures" */ "@bokio/closures/src/scenes/AnnualReport/AnnualReportFormScene");
    },
  }),
  closureNEAttachment: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "closures" */ "@bokio/closures/src/scenes/ClosureReports/NEAttachment/NEAttachment");
    },
  }),
  closuresShow: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "closures" */ "@bokio/closures/src/scenes/ClosureShow/ClosureShow");
    },
  }),
  annualReportArchive: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "closures" */ "@bokio/closures/src/scenes/AnnualReportArchive/AnnualReportArchiveScene");
    },
  }),
  FetchFiles: ServerRouteRedirect,
  closures: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "closures" */ "@bokio/closures/src/scenes/ClosureList/ClosureList");
    },
  }),
  /* Todo */
  todo: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "todo" */ "@bokio/todo/src/scenes/Todo/TodoScene");
    },
  }),
  importantDates: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "todo" */ "@bokio/todo/src/scenes/Todo/ImportantDatesScene");
    },
  }),
  preliminaryTaxes: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "todo" */ "@bokio/todo/src/scenes/Taxes/PreliminaryTaxesScene");
    },
  }),
  preliminaryTaxesPaymentDetails: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "todo" */ "@bokio/todo/src/scenes/Taxes/PreliminaryTaxesPaymentDetailsScene");
    },
  }),
  todoPromotionAvtal24: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "todo" */ "@bokio/todo/src/scenes/TodoPromotionScenes/Avtal24Promotion/Avtal24PromotionScene");
    },
  }),
  todoPromotionMynt: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "todo" */ "@bokio/todo/src/scenes/TodoPromotionScenes/MyntPromotion/MyntPromotionScene");
    },
  }),
  /* Agencies */
  agencyIndex: ServerRouteRedirect,
  createAgency: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/guest/src/scenes/SignUp/CreateAgencyScene");
    },
    narrowPage: true
  }),
  preCreateAgency: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/guest/src/scenes/SignUp/CreateAgencyPreScene");
    },
    narrowPage: true
  }),
  agencyOverview: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyOverview/AgencyOverviewScene");
    },
  }),
  agencyPartnerInfo: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyPartnerInfo/AgencyPartnerInfoScene");
    },
  }),
  agencyClients: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyClients/AgencyClientsScene");
    },
  }),
  agencyUsers: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyUsers/AgencyUsersScene");
    },
  }),
  agencyNotes: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyNotes/AgencyNotesScene");
    },
  }),
  agencyClientDetails: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyClientDetails/AgencyClientDetailsScene");
    },
  }),
  exportNotes: ServerRouteRedirect,
  AgencyPartnerContractPdf: ServerRouteRedirect,
  agencySettings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencySettings/AgencySettingsScene");
    },
  }),
  agencyImportClients: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyImportClients/AgencyImportClientsScene");
    },
  }),
  agencyClientRequests: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyClientRequests/AgencyClientRequestsScene");
    },
  }),
  agencyDetailsScene: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyDetails/AgencyDetailsScene");
    },
  }),
  agencyNotificationSettings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyNotificationSettings/AgencyNotificationSettingsScene");
    },
  }),
  agencyExport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyExport/AgencyExportScene");
    },
  }),
  leaveAgency: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/LeaveAgency/LeaveAgencyScene");
    },
  }),
  deleteAgency: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/DeleteAgency/DeleteAgencyScene");
    },
  }),
  backofficeFeatureToggles: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/BackofficeFeatureToggles/BackofficeFeatureTogglesScene");
    },
  }),
  agencyPartnerProgram: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyPartnerProgram/AgencyPartnerProgramScene");
    },
  }),
  agencyQuoteRequestsList: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyQuoteRequests/AgencyQuoteRequestsListScene");
    },
  }),
  agencyQuoteRequest: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyQuoteRequests/AgencyQuoteRequestScene");
    },
  }),
  agencyNotificationRedirect: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "agencies" */ "@bokio/backoffice/src/scenes/AgencyNotificationRedirect/AgencyNotificationRedirectScene");
    },
  }),
  /* BackOffice */
  backOfficeAcceptInvitation: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "backoffice" */ "@bokio/backoffice/src/scenes/BackOfficeAcceptInvitation/BackOfficeAcceptInvitationScene");
    },
  }),
  recordingSupport: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "backoffice" */ "@bokio/backoffice/src/scenes/RecordingSupport/RecordingSupportScene");
    },
  }),
  supportTicketReceiptImage: ServerRouteRedirect,
  supportTicketReceiptExtraPage: ServerRouteRedirect,
  backOffice: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "backoffice" */ "@bokio/backoffice/src/scenes/BackOfficeLayout");
    },
    narrowPage: true
  }),
  /* Companies */
  root: ServerRouteRedirect,
  companyIndex: ServerRouteRedirect,
  selectCompanyFromMail: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "companies" */ "@bokio/companies/src/scenes/SelectCompany/SelectCompanyFromMailScene");
    },
  }),
  selectCompany: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "companies" */ "@bokio/companies/src/scenes/SelectCompany/SelectCompanyScene");
    },
  }),
  dashboard: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "companies" */ "@bokio/companies/src/scenes/Overview/OverviewScene");
    },
  }),
  onboarding: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "companies" */ "@bokio/companies/src/scenes/Onboarding/OnboardingScene");
    },
  }),
  /* Services */
  accountingServices: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "services" */ "@bokio/settings/src/scenes/AccountingServices/AccountingServicesScene");
    },
  }),
  submitAgencyInterest: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "services" */ "@bokio/settings/src/scenes/PartnerOnboarding/AgencyRequestScene");
    },
  }),
  businessServices: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "services" */ "@bokio/settings/src/scenes/BusinessServices/BusinessServicesScene");
    },
  }),
  /* Accounting */
  bankTransactions: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "accounting" */ "@bokio/bookkeeping/src/scenes/BankTransactions/BankTransactionsScene");
    },
  }),
  assetsManagement: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "accounting" */ "@bokio/bookkeeping/src/scenes/AssetManagement/AssetManagementScene");
    },
  }),
  /* Uploads */
  uploads: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "uploads" */ "@bokio/uploads/src/scenes/UploadsScene");
    },
  }),
  downloadAdditionalFile: ServerRouteRedirect,
  downloadAdditionalFilesAsZip: ServerRouteRedirect,
  /* Integrations */
  integrations: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "integrations" */ "@bokio/integrations/src/scenes/Integrations/IntegrationsScene");
    },
  }),
  stripe: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "integrations" */ "@bokio/integrations/src/scenes/Stripe/StripeScene");
    },
  }),
  stripeCallback: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "integrations" */ "@bokio/integrations/src/scenes/OauthStripeCallback/OauthStripeCallbackScene");
    },
  }),
  stripeOnboardingMock: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "integrations" */ "@bokio/integrations/src/scenes/StripeMockOnboarding/StripeMockOnboardingScene");
    },
  }),
  /* CustomerPortal */
  invoicePortal: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "customerportal" */ "@bokio/customerportal/src/scenes/InvoicePortal/InvoicePortalScene");
    },
  }),
  invoicePortalPdf: ServerRouteRedirect,
  /* DirectorsPayroll */
  employerDirectorSetup: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "directorspayroll" */ "@bokio/employeeswithcontracts/src/scenes/DirectorsPayroll/EmployerDirectorSetupScene");
    },
  }),
  directorsPayrollList: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "directorspayroll" */ "@bokio/directorspayroll/src/scenes/DirectorsPayroll/DirectorsPayrollScene");
    },
  }),
  directorsPayrollDetails: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "directorspayroll" */ "@bokio/directorspayroll/src/scenes/DirectorsPayrollDetails/DirectorsPayrollDetailsScene");
    },
  }),
  addDirector: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "directorspayroll" */ "@bokio/employeeswithcontracts/src/scenes/AddDirector/AddDirectorScene");
    },
  }),
  /* Sales */
  dailyTakings: createBundleLoader({
    loader: () => {
      return import(/* webpackChunkName: "sales" */ "@bokio/sales/src/scenes/DailyTakings/DailyTakingsScene");
    },
  })
}

export const Scenes = () => {
	return <React.Fragment>          
          {/* Signup */}
          <GuestRoute path={routes.signUp} component={scenes.signUp} exact={true} />
          <GuestRoute path={routes.signupStart} component={scenes.signupStart} exact={true} />
          <GuestRoute path={routes.signupOrLogin} component={scenes.signupOrLogin} exact={true} />
          <GuestRoute path={routes.signupBankIdEmail} component={scenes.signupBankIdEmail} exact={true} />
          <GuestRoute path={routes.signupMultipleAccounts} component={scenes.signupMultipleAccounts} exact={true} />
          <GuestRoute path={routes.signUpAccountant} component={scenes.signUpAccountant} exact={true} />
          <GuestRoute path={routes.login} component={scenes.login} exact={true} />
          <GuestRoute path={routes.connectAgency} component={scenes.connectAgency} exact={true} />
          <GuestRoute path={routes.forgotPassword} component={scenes.forgotPassword} exact={true} />
          <GuestRoute path={routes.resetPassword} component={scenes.resetPassword} exact={true} />
          <GuestRoute path={routes.acceptInvitation} component={scenes.acceptInvitation} exact={true} />
          <GuestRoute path={routes.acceptCompanyOwnerInvitation} component={scenes.acceptCompanyOwnerInvitation} exact={true} />
          <GuestRoute path={routes.confirmEmail} component={scenes.confirmEmail} exact={true} />
          <GuestRoute path={routes.integrationTests} component={scenes.integrationTests} exact={true} />
          <GuestRoute path={routes.voucherFlow} component={scenes.voucherFlow} exact={true} />
          
          {/* Settings */}
          <UserRoute path={routes.createCompany} component={scenes.createCompany} exact={true} />
          <UserRoute path={routes.preCreateClient} component={scenes.preCreateClient} exact={true} />
          <CompanyRoute path={routes.settingsImportCompany} component={scenes.settingsImportCompany} exact={true} />
          <CompanyRoute path={routes.settingsImportOverview} component={scenes.settingsImportOverview} exact={true} />
          <CompanyRoute path={routes.fiscalList} component={scenes.fiscalList} exact={true} />
          <CompanyRoute path={routes.fiscalEdit} component={scenes.fiscalEdit} exact={true} />
          <CompanyRoute path={routes.fiscalCreate} component={scenes.fiscalCreate} exact={true} />
          <CompanyRoute path={routes.accountPlan} component={scenes.accountPlan} exact={true} />
          <CompanyRoute path={routes.mapAccounts} component={scenes.mapAccounts} exact={true} />
          <CompanyRoute path={routes.invoiceSettings} component={scenes.invoiceSettings} exact={true} />
          <CompanyRoute path={routes.eInvoiceSettings} component={scenes.eInvoiceSettings} exact={true} />
          <CompanyRoute path={routes.automaticCheckingSettings} component={scenes.automaticCheckingSettings} exact={true} />
          <CompanyRoute path={routes.paymentMethodSettings} component={scenes.paymentMethodSettings} exact={true} />
          <CompanyRoute path={routes.settingsOverview} component={scenes.settingsOverview} exact={true} />
          <UserRoute path={routes.personalSettings} component={scenes.personalSettings} exact={true} />
          <UserRoute path={routes.companyDataStorage} component={scenes.companyDataStorage} exact={true} />
          <UserRoute path={routes.totpActivate} component={scenes.totpActivate} exact={true} />
          <UserRoute path={routes.totpDeactivate} component={scenes.totpDeactivate} exact={true} />
          <UserRoute path={routes.totpSettings} component={scenes.totpSettings} exact={true} />
          <Route path={routes.totpRecoveryDocument} component={scenes.totpRecoveryDocument} exact={true} />
          <UserRoute path={routes.recentLogins} component={scenes.recentLogins} exact={true} />
          <UserRoute path={routes.logOutEverywhere} component={scenes.logOutEverywhere} exact={true} />
          <UserRoute path={routes.bankidSettings} component={scenes.bankidSettings} exact={true} />
          <UserRoute path={routes.bankidActivate} component={scenes.bankidActivate} exact={true} />
          <UserRoute path={routes.bankidDeactivate} component={scenes.bankidDeactivate} exact={true} />
          <UserRoute path={routes.bankIdRequired} component={scenes.bankIdRequired} exact={true} />
          <UserRoute path={routes.updateUsername} component={scenes.updateUsername} exact={true} />
          <UserRoute path={routes.confirmUpdateUsername} component={scenes.confirmUpdateUsername} exact={true} />
          <CompanyRoute path={routes.moneyAccounts} component={scenes.moneyAccounts} exact={true} />
          <CompanyRoute path={routes.tagTypes} component={scenes.tagTypes} exact={true} />
          <CompanyRoute path={routes.exportBookkeeping} component={scenes.exportBookkeeping} exact={true} />
          <CompanyRoute path={routes.openingBalances} component={scenes.openingBalances} exact={true} />
          <CompanyRoute path={routes.accountPayables} component={scenes.accountPayables} exact={true} />
          <CompanyRoute path={routes.accountReceivables} component={scenes.accountReceivables} exact={true} />
          <CompanyRoute path={routes.layoutLogoColor} component={scenes.layoutLogoColor} exact={true} />
          <CompanyRoute path={routes.companySettings} component={scenes.companySettings} exact={true} />
          <CompanyRoute path={routes.changeCompanySystem} component={scenes.changeCompanySystem} exact={true} />
          <CompanyRoute path={routes.deleteCompany} component={scenes.deleteCompany} exact={true} />
          <CompanyRoute path={routes.leaveCompany} component={scenes.leaveCompany} exact={true} />
          <UserRoute path={routes.deleteUserAccount} component={scenes.deleteUserAccount} exact={true} />
          <CompanyRoute path={routes.featureToggles} component={scenes.featureToggles} exact={true} />
          <GuestRoute path={routes.agreements} component={scenes.agreements} exact={true} />
          <UserRoute path={routes.signTerms} component={scenes.signTerms} exact={true} />
          <Route path={routes.settings} component={scenes.settings} exact={true} />
          <Route path={routes.companySetting} component={scenes.companySetting} exact={true} />
          <CompanyRoute path={routes.inbox} component={scenes.inbox} exact={true} />
          <CompanyRoute path={routes.inboxHistory} component={scenes.inboxHistory} exact={true} />
          <Route path={routes.inboxAttachmentDownload} component={scenes.inboxAttachmentDownload} exact={true} />
          <CompanyRoute path={routes.exportData} component={scenes.exportData} exact={true} />
          <CompanyRoute path={routes.users} component={scenes.users} exact={true} />
          <UserRoute path={routes.changePassword} component={scenes.changePassword} exact={true} />
          <CompanyRoute path={routes.auditHistory} component={scenes.auditHistory} exact={true} />
          <Route path={routes.exportAuditHistory} component={scenes.exportAuditHistory} exact={true} />
          <CompanyRoute path={routes.companyVacationSettings} component={scenes.companyVacationSettings} exact={true} />
          <CompanyRoute path={routes.placeOfEmploymentSettings} component={scenes.placeOfEmploymentSettings} exact={true} />
          <CompanyRoute path={routes.salarySettingUK} component={scenes.salarySettingUK} exact={true} />
          <CompanyRoute path={routes.activateVoucher} component={scenes.activateVoucher} exact={true} />
          <CompanyRoute path={routes.billing} component={scenes.billing} exact={true} />
          <CompanyRoute path={routes.viewBill} component={scenes.viewBill} exact={true} />
          <Route path={routes.downloadBillingReceiptPdf} component={scenes.downloadBillingReceiptPdf} exact={true} />
          <CompanyRoute path={routes.cardList} component={scenes.cardList} exact={true} />
          <CompanyRoute path={routes.card} component={scenes.card} exact={true} />
          <CompanyRoute path={routes.orderCard} component={scenes.orderCard} exact={true} />
          <CompanyRoute path={routes.supportMessages} component={scenes.supportMessages} exact={true} />
          <CompanyRoute path={routes.bokioBusinessAccountOffboarding} component={scenes.bokioBusinessAccountOffboarding} exact={true} />
          <CompanyRoute path={routes.sveaBankConsent} component={scenes.sveaBankConsent} exact={true} />
          <CompanyRoute path={routes.sveaKycScene} component={scenes.sveaKycScene} exact={true} />
          <CompanyRoute path={routes.notificationSettings} component={scenes.notificationSettings} exact={true} />
          <CompanyRoute path={routes.notificationRedirect} component={scenes.notificationRedirect} exact={true} />
          <Route path={routes.exportSIEFile} component={scenes.exportSIEFile} exact={true} />
          <CompanyRoute path={routes.customTemplates} component={scenes.customTemplates} exact={true} />
          <CompanyRoute path={routes.automationRules} component={scenes.automationRules} exact={true} />
          <CompanyRoute path={routes.mirChangeSignatory} component={scenes.mirChangeSignatory} exact={true} />
          <CompanyRoute path={routes.removeImport} component={scenes.removeImport} exact={true} />
          
          {/* Bookkeeping */}
          <UserRoute path={routes.hmrcAuth} component={scenes.hmrcAuth} exact={true} />
          <Route path={routes.accounting} component={scenes.accounting} exact={true} />
          <CompanyRoute path={[routes.preBookkeep, routes.preBookkeepSupplierInvoice]} component={scenes.preBookkeep} exact={true} />
          <CompanyRoute path={routes.bookkeepReceipt} component={scenes.bookkeepReceipt} exact={true} />
          <CompanyRoute path={routes.recordingCheckList} component={scenes.recordingCheckList} exact={true} />
          <CompanyRoute path={routes.recordingCheckCreate} component={scenes.recordingCheckCreate} exact={true} />
          <CompanyRoute path={routes.recordingCheckView} component={scenes.recordingCheckView} exact={true} />
          <CompanyRoute path={routes.bookkeepExpense} component={scenes.bookkeepExpense} exact={true} />
          <CompanyRoute path={routes.bookkeepSupplier} component={scenes.bookkeepSupplier} exact={true} />
          <CompanyRoute path={routes.bookkeepTransaction} component={scenes.bookkeepTransaction} exact={true} />
          <CompanyRoute path={routes.editVerification} component={scenes.editVerification} exact={true} />
          <CompanyRoute path={routes.attest} component={scenes.attest} exact={true} />
          <CompanyRoute path={routes.eventsToRecord} component={scenes.eventsToRecord} exact={true} />
          <Route path={routes.receiptImage} component={scenes.receiptImage} exact={true} />
          <Route path={routes.downloadReceipt} component={scenes.downloadReceipt} exact={true} />
          <Route path={routes.receiptThumbnail} component={scenes.receiptThumbnail} exact={true} />
          <Route path={routes.receiptThumbnailAlt} component={scenes.receiptThumbnailAlt} exact={true} />
          <Route path={routes.receiptExtraImage} component={scenes.receiptExtraImage} exact={true} />
          <Route path={routes.loginToArsRedovisningOnline} component={scenes.loginToArsRedovisningOnline} exact={true} />
          <CompanyRoute path={routes.importSi} component={scenes.importSi} exact={true} />
          
          {/* Bank */}
          <CompanyRoute path={routes.bankImport} component={scenes.bankImport} exact={true} />
          <CompanyRoute path={routes.bankList} component={scenes.bankList} exact={true} />
          <CompanyRoute path={routes.bokioBusinessAccountMigration} component={scenes.bokioBusinessAccountMigration} exact={true} />
          <CompanyRoute path={routes.sveaOnboarding} component={scenes.sveaOnboarding} exact={true} />
          <Route path={routes.sveaOnboardingAgreementPdf} component={scenes.sveaOnboardingAgreementPdf} exact={true} />
          <CompanyRoute path={routes.sveaAddInternationalPaymentsScene} component={scenes.sveaAddInternationalPaymentsScene} exact={true} />
          <CompanyRoute path={routes.sveaInternationalPayments} component={scenes.sveaInternationalPayments} exact={true} />
          <CompanyRoute path={routes.sveaPreregistrationViewScene} component={scenes.sveaPreregistrationViewScene} exact={true} />
          <CompanyRoute path={routes.sveaMatchingScene} component={scenes.sveaMatchingScene} exact={true} />
          <CompanyRoute path={routes.sveaAddSavingsAccount} component={scenes.sveaAddSavingsAccount} exact={true} />
          <CompanyRoute path={routes.sveaSwishOverview} component={scenes.sveaSwishOverview} exact={true} />
          <CompanyRoute path={routes.sveaSwishOnboarding} component={scenes.sveaSwishOnboarding} exact={true} />
          <CompanyRoute path={routes.sveaInternationalPaymentsPreregistrations} component={scenes.sveaInternationalPaymentsPreregistrations} exact={true} />
          <CompanyRoute path={routes.sveaInternationalPaymentsPreregistrationDetails} component={scenes.sveaInternationalPaymentsPreregistrationDetails} exact={true} />
          <CompanyRoute path={routes.bankManage} component={scenes.bankManage} exact={true} />
          <CompanyRoute path={routes.bankAccountEdit} component={scenes.bankAccountEdit} exact={true} />
          <CompanyRoute path={routes.bankAccount} component={scenes.bankAccount} exact={true} />
          <CompanyRoute path={routes.bankInbox} component={scenes.bankInbox} exact={true} />
          <Route path={routes.sveaInboxAttachmentFile} component={scenes.sveaInboxAttachmentFile} exact={true} />
          <Route path={routes.bankAccountQrCode} component={scenes.bankAccountQrCode} exact={true} />
          <CompanyRoute path={routes.bankPayments} component={scenes.bankPayments} exact={true} />
          <CompanyRoute path={routes.bankCreatePayment} component={scenes.bankCreatePayment} exact={true} />
          <CompanyRoute path={routes.bankCreatePaymentSuccess} component={scenes.bankCreatePaymentSuccess} exact={true} />
          <CompanyRoute path={routes.bokioBusinessAccountOnboardingSteps} component={scenes.bokioBusinessAccountOnboardingSteps} exact={true} />
          <Route path={routes.mirOnboardingAgreementPdf} component={scenes.mirOnboardingAgreementPdf} exact={true} />
          <Route path={routes.bankStatementPdf} component={scenes.bankStatementPdf} exact={true} />
          <Route path={routes.transactionDetailsPdf} component={scenes.transactionDetailsPdf} exact={true} />
          <CompanyRoute path={routes.blockedBankList} component={scenes.blockedBankList} exact={true} />
          
          {/* Supplier invoices */}
          <CompanyRoute path={routes.suppliersInvoicesAll} component={scenes.suppliersInvoicesAll} exact={true} />
          <CompanyRoute path={routes.supplierInvoices} component={scenes.supplierInvoices} exact={true} />
          <CompanyRoute path={routes.supplierInvoice} component={scenes.supplierInvoice} exact={true} />
          <CompanyRoute path={routes.suppliers} component={scenes.suppliers} exact={true} />
          <CompanyRoute path={routes.suppliersImport} component={scenes.suppliersImport} exact={true} />
          <Route path={routes.supplierInvoiceQrCode} component={scenes.supplierInvoiceQrCode} exact={true} />
          
          {/* Customer invoices */}
          <CompanyRoute path={routes.periodicReport} component={scenes.periodicReport} exact={true} />
          <CompanyRoute path={routes.invoicesShow} component={scenes.invoicesShow} exact={true} />
          <CompanyRoute path={[routes.invoicesCreate, routes.invoicesEdit]} component={scenes.invoicesCreateOrEdit} exact={true} />
          <CompanyRoute path={routes.invoices} component={scenes.invoices} exact={true} />
          <CompanyRoute path={routes.articles} component={scenes.articles} exact={true} />
          <CompanyRoute path={routes.articlesImport} component={scenes.articlesImport} exact={true} />
          <CompanyRoute path={routes.rotRutErrands} component={scenes.rotRutErrands} exact={true} />
          <Route path={routes.logoImageUrl} component={scenes.logoImageUrl} exact={true} />
          <CompanyRoute path={routes.factoringActivation} component={scenes.factoringActivation} exact={true} />
          <Route path={routes.exportArticles} component={scenes.exportArticles} exact={true} />
          <Route path={routes.customerDownload} component={scenes.customerDownload} exact={true} />
          <Route path={routes.invoiceLatestPdf} component={scenes.invoiceLatestPdf} exact={true} />
          <Route path={routes.invoicePdf} component={scenes.invoicePdf} exact={true} />
          <Route path={routes.rotXML} component={scenes.rotXML} exact={true} />
          <Route path={routes.rutXML} component={scenes.rutXML} exact={true} />
          <Route path={routes.supplierInvoiceImage} component={scenes.supplierInvoiceImage} exact={true} />
          <Route path={routes.exportInvoices} component={scenes.exportInvoices} exact={true} />
          <CompanyRoute path={routes.customersImport} component={scenes.customersImport} exact={true} />
          <CompanyRoute path={routes.customers} component={scenes.customers} exact={true} />
          <CompanyRoute path={routes.customerInvoices} component={scenes.customerInvoices} exact={true} />
          <Route path={routes.exportCustomers} component={scenes.exportCustomers} exact={true} />
          <Route path={routes.exportAccountsReceivable} component={scenes.exportAccountsReceivable} exact={true} />
          <Route path={routes.exportAccountsPayable} component={scenes.exportAccountsPayable} exact={true} />
          <Route path={routes.invoiceAttachment} component={scenes.invoiceAttachment} exact={true} />
          <CompanyRoute path={[routes.quotesCreate, routes.quotesEdit]} component={scenes.quotesCreateEdit} exact={true} />
          <CompanyRoute path={routes.quoteDetails} component={scenes.quoteDetails} exact={true} />
          <CompanyRoute path={routes.quotes} component={scenes.quotes} exact={true} />
          <Route path={routes.quotePdf} component={scenes.quotePdf} exact={true} />
          <CompanyRoute path={routes.recurringInvoiceDetailsOverview} component={scenes.recurringInvoiceDetailsOverview} exact={true} />
          <CompanyRoute path={routes.recurringInvoices} component={scenes.recurringInvoices} exact={true} />
          
          {/* Expenses */}
          <CompanyRoute path={routes.expenses} component={scenes.expenses} exact={true} />
          <CompanyRoute path={routes.expensesUpload} component={scenes.expensesUpload} exact={true} />
          <CompanyRoute path={routes.expensesUploadShow} component={scenes.expensesUploadShow} exact={true} />
          <CompanyRoute path={routes.expenseReceiptView} component={scenes.expenseReceiptView} exact={true} />
          <CompanyRoute path={routes.expensesPaymentsList} component={scenes.expensesPaymentsList} exact={true} />
          <CompanyRoute path={routes.expensesPayments} component={scenes.expensesPayments} exact={true} />
          <CompanyRoute path={routes.showExpensePayment} component={scenes.showExpensePayment} exact={true} />
          <CompanyRoute path={routes.expensesPermissions} component={scenes.expensesPermissions} exact={true} />
          <CompanyRoute path={routes.expensesOverview} component={scenes.expensesOverview} exact={true} />
          <CompanyRoute path={routes.expenseSettings} component={scenes.expenseSettings} exact={true} />
          <CompanyRoute path={routes.myExpenseAccounts} component={scenes.myExpenseAccounts} exact={true} />
          <CompanyRoute path={routes.companyCards} component={scenes.companyCards} exact={true} />
          <CompanyRoute path={routes.convertToExpenses} component={scenes.convertToExpenses} exact={true} />
          
          {/* Salary */}
          <CompanyRoute path={routes.salarySettings} component={scenes.salarySettings} exact={true} />
          <CompanyRoute path={routes.salaryPayrollPayslipShow} component={scenes.salaryPayrollPayslipShow} exact={true} />
          <CompanyRoute path={routes.salaryPayrollTaxreport} component={scenes.salaryPayrollTaxreport} exact={true} />
          <Route path={routes.salaryTaxreportRedirect} component={scenes.salaryTaxreportRedirect} exact={true} />
          <Route path={routes.myPayslipPdf} component={scenes.myPayslipPdf} exact={true} />
          <Route path={routes.bulkpayslipPdf} component={scenes.bulkpayslipPdf} exact={true} />
          <Route path={routes.p60Pdf} component={scenes.p60Pdf} exact={true} />
          <Route path={routes.P45Pdf} component={scenes.P45Pdf} exact={true} />
          <CompanyRoute path={routes.salary2Payroll_old} component={scenes.salary2Payroll_old} exact={true} />
          <CompanyRoute path={routes.salaryPayrollDetails} component={scenes.salaryPayrollDetails} exact={true} />
          <Route path={routes.salaryAGIdownload} component={scenes.salaryAGIdownload} exact={true} />
          <CompanyRoute path={routes.salaryTaxPeriodList} component={scenes.salaryTaxPeriodList} exact={true} />
          <CompanyRoute path={routes.salaryTaxPeriodView} component={scenes.salaryTaxPeriodView} exact={true} />
          
          {/* Salary/Absence */}
          <CompanyRoute path={routes.salaryAbsense} component={scenes.salaryAbsense} exact={true} />
          <CompanyRoute path={routes.salaryAGI} component={scenes.salaryAGI} exact={true} />
          
          {/* Reports */}
          <CompanyRoute path={routes.balanceReport} component={scenes.balanceReport} exact={true} />
          <CompanyRoute path={routes.balanceReportAccountPage} component={scenes.balanceReportAccountPage} exact={true} />
          <CompanyRoute path={routes.vatList} component={scenes.vatList} exact={true} />
          <CompanyRoute path={routes.vatReport} component={scenes.vatReport} exact={true} />
          <CompanyRoute path={routes.vatReport2} component={scenes.vatReport2} exact={true} />
          <CompanyRoute path={routes.vatReportHmrc} component={scenes.vatReportHmrc} exact={true} />
          <CompanyRoute path={routes.vatCheck} component={scenes.vatCheck} exact={true} />
          <CompanyRoute path={routes.hmrcVatCheck} component={scenes.hmrcVatCheck} exact={true} />
          <CompanyRoute path={routes.resultReport} component={scenes.resultReport} exact={true} />
          <CompanyRoute path={routes.tagReport} component={scenes.tagReport} exact={true} />
          <CompanyRoute path={routes.drillDownReport} component={scenes.drillDownReport} exact={true} />
          <CompanyRoute path={routes.resultReportAccountPage} component={scenes.resultReportAccountPage} exact={true} />
          <CompanyRoute path={routes.ledgerReconcilliation} component={scenes.ledgerReconcilliation} exact={true} />
          <CompanyRoute path={routes.ledgerReport} component={scenes.ledgerReport} exact={true} />
          <CompanyRoute path={routes.accountedList} component={scenes.accountedList} exact={true} />
          <CompanyRoute path={routes.accountingCheckShow} component={scenes.accountingCheckShow} exact={true} />
          <CompanyRoute path={routes.checkVerifications} component={scenes.checkVerifications} exact={true} />
          <Route path={routes.vatReportXmlDownload} component={scenes.vatReportXmlDownload} exact={true} />
          <Route path={routes.balanceReportPdf} component={scenes.balanceReportPdf} exact={true} />
          <Route path={routes.balanceReportExcel} component={scenes.balanceReportExcel} exact={true} />
          <Route path={routes.resultReportPdf} component={scenes.resultReportPdf} exact={true} />
          <Route path={routes.resultReportExcel} component={scenes.resultReportExcel} exact={true} />
          <Route path={routes.ledgerPdf} component={scenes.ledgerPdf} exact={true} />
          <Route path={routes.trialBalanceExcel} component={scenes.trialBalanceExcel} exact={true} />
          <Route path={routes.ledgerAccountExcel} component={scenes.ledgerAccountExcel} exact={true} />
          <CompanyRoute path={routes.invoicesReport} component={scenes.invoicesReport} exact={true} />
          <CompanyRoute path={routes.salaryReports} component={scenes.salaryReports} exact={true} />
          <CompanyRoute path={routes.salaryReportSalaryTotal} component={scenes.salaryReportSalaryTotal} exact={true} />
          <CompanyRoute path={routes.salaryReportVacationSummary} component={scenes.salaryReportVacationSummary} exact={true} />
          <CompanyRoute path={routes.salaryReportSickDays} component={scenes.salaryReportSickDays} exact={true} />
          <CompanyRoute path={routes.salaryReportHourlyPaid} component={scenes.salaryReportHourlyPaid} exact={true} />
          <CompanyRoute path={routes.salaryReportVacationDebt} component={scenes.salaryReportVacationDebt} exact={true} />
          <CompanyRoute path={routes.agedDebtorsReport} component={scenes.agedDebtorsReport} exact={true} />
          <Route path={routes.agedDebtorsReportPdf} component={scenes.agedDebtorsReportPdf} exact={true} />
          <CompanyRoute path={routes.agedCreditorsReport} component={scenes.agedCreditorsReport} exact={true} />
          <Route path={routes.accountPayablePdf} component={scenes.accountPayablePdf} exact={true} />
          
          {/* EmployeesWithContracts */}
          <CompanyRoute path={routes.employeeList} component={scenes.employeeList} exact={true} />
          <CompanyRoute path={routes.employeeAdd} component={scenes.employeeAdd} exact={true} />
          <CompanyRoute path={routes.manageActiveEmployees} component={scenes.manageActiveEmployees} exact={true} />
          <CompanyRoute path={routes.employeeDetails} component={scenes.employeeDetails} exact={true} />
          <CompanyRoute path={routes.employeeVacationTrackingForm} component={scenes.employeeVacationTrackingForm} exact={true} />
          <CompanyRoute path={routes.employeeVacationForm} component={scenes.employeeVacationForm} exact={true} />
          <CompanyRoute path={routes.vacationTracking} component={scenes.vacationTracking} exact={true} />
          <CompanyRoute path={routes.vacationTrackingSetup} component={scenes.vacationTrackingSetup} exact={true} />
          <CompanyRoute path={routes.employeeView} component={scenes.employeeView} exact={true} />
          <CompanyRoute path={routes.editDirector} component={scenes.editDirector} exact={true} />
          <CompanyRoute path={routes.contractView} component={scenes.contractView} exact={true} />
          <CompanyRoute path={routes.contractAdd} component={scenes.contractAdd} exact={true} />
          <CompanyRoute path={routes.contractEdit} component={scenes.contractEdit} exact={true} />
          <CompanyRoute path={routes.myContractView} component={scenes.myContractView} exact={true} />
          <CompanyRoute path={routes.myEmployee} component={scenes.myEmployee} exact={true} />
          <CompanyRoute path={routes.myEmployeeSalary} component={scenes.myEmployeeSalary} exact={true} />
          <CompanyRoute path={routes.employeesImport} component={scenes.employeesImport} exact={true} />
          <Route path={routes.exportEmployees} component={scenes.exportEmployees} exact={true} />
          <Route path={routes.employeeDownload} component={scenes.employeeDownload} exact={true} />
          <CompanyRoute path={routes.vacationYearEnd} component={scenes.vacationYearEnd} exact={true} />
          <CompanyRoute path={routes.vacationYearEndEmployeeDetail} component={scenes.vacationYearEndEmployeeDetail} exact={true} />
          <Route path={routes.GetMyP45} component={scenes.GetMyP45} exact={true} />
          <Route path={routes.GetMyP60} component={scenes.GetMyP60} exact={true} />
          
          {/* Development */}
          <CompanyRoute path={routes.developerTools} component={scenes.developerTools} exact={true} />
          <CompanyRoute path={routes.mockTools} component={scenes.mockTools} exact={true} />
          <CompanyRoute path={routes.stagingEnableBankingScene} component={scenes.stagingEnableBankingScene} exact={true} />
          
          {/* Closures */}
          <CompanyRoute path={routes.annualReportForm} component={scenes.annualReportForm} exact={true} />
          <CompanyRoute path={routes.closureNEAttachment} component={scenes.closureNEAttachment} exact={true} />
          <CompanyRoute path={routes.closuresShow} component={scenes.closuresShow} exact={true} />
          <CompanyRoute path={routes.annualReportArchive} component={scenes.annualReportArchive} exact={true} />
          <Route path={routes.FetchFiles} component={scenes.FetchFiles} exact={true} />
          <CompanyRoute path={routes.closures} component={scenes.closures} exact={true} />
          
          {/* Todo */}
          <CompanyRoute path={routes.todo} component={scenes.todo} exact={true} />
          <CompanyRoute path={routes.importantDates} component={scenes.importantDates} exact={true} />
          <CompanyRoute path={routes.preliminaryTaxes} component={scenes.preliminaryTaxes} exact={true} />
          <CompanyRoute path={routes.preliminaryTaxesPaymentDetails} component={scenes.preliminaryTaxesPaymentDetails} exact={true} />
          <CompanyRoute path={routes.todoPromotionAvtal24} component={scenes.todoPromotionAvtal24} exact={true} />
          <CompanyRoute path={routes.todoPromotionMynt} component={scenes.todoPromotionMynt} exact={true} />
          
          {/* Agencies */}
          <Route path={routes.agencyIndex} component={scenes.agencyIndex} exact={true} />
          <UserRoute path={routes.createAgency} component={scenes.createAgency} exact={true} />
          <UserRoute path={routes.preCreateAgency} component={scenes.preCreateAgency} exact={true} />
          <AgencyRoute path={routes.agencyOverview} component={scenes.agencyOverview} exact={true} />
          <AgencyRoute path={routes.agencyPartnerInfo} component={scenes.agencyPartnerInfo} exact={true} />
          <AgencyRoute path={routes.agencyClients} component={scenes.agencyClients} exact={true} />
          <AgencyRoute path={routes.agencyUsers} component={scenes.agencyUsers} exact={true} />
          <AgencyRoute path={routes.agencyNotes} component={scenes.agencyNotes} exact={true} />
          <AgencyRoute path={routes.agencyClientDetails} component={scenes.agencyClientDetails} exact={true} />
          <Route path={routes.exportNotes} component={scenes.exportNotes} exact={true} />
          <Route path={routes.AgencyPartnerContractPdf} component={scenes.AgencyPartnerContractPdf} exact={true} />
          <AgencyRoute path={routes.agencySettings} component={scenes.agencySettings} exact={true} />
          <AgencyRoute path={routes.agencyImportClients} component={scenes.agencyImportClients} exact={true} />
          <AgencyRoute path={routes.agencyClientRequests} component={scenes.agencyClientRequests} exact={true} />
          <AgencyRoute path={routes.agencyDetailsScene} component={scenes.agencyDetailsScene} exact={true} />
          <AgencyRoute path={routes.agencyNotificationSettings} component={scenes.agencyNotificationSettings} exact={true} />
          <AgencyRoute path={routes.agencyExport} component={scenes.agencyExport} exact={true} />
          <AgencyRoute path={routes.leaveAgency} component={scenes.leaveAgency} exact={true} />
          <AgencyRoute path={routes.deleteAgency} component={scenes.deleteAgency} exact={true} />
          <AgencyRoute path={routes.backofficeFeatureToggles} component={scenes.backofficeFeatureToggles} exact={true} />
          <AgencyRoute path={routes.agencyPartnerProgram} component={scenes.agencyPartnerProgram} exact={true} />
          <AgencyRoute path={routes.agencyQuoteRequestsList} component={scenes.agencyQuoteRequestsList} exact={true} />
          <AgencyRoute path={routes.agencyQuoteRequest} component={scenes.agencyQuoteRequest} exact={true} />
          <AgencyRoute path={routes.agencyNotificationRedirect} component={scenes.agencyNotificationRedirect} exact={true} />
          
          {/* BackOffice */}
          <Route path={routes.backOfficeAcceptInvitation} component={scenes.backOfficeAcceptInvitation} exact={true} />
          <Route path={routes.recordingSupport} component={scenes.recordingSupport} exact={true} />
          <Route path={routes.supportTicketReceiptImage} component={scenes.supportTicketReceiptImage} exact={true} />
          <Route path={routes.supportTicketReceiptExtraPage} component={scenes.supportTicketReceiptExtraPage} exact={true} />
          <Route path={[routes.backOffice, routes.backOfficeAdmin, routes.backOfficeDashboard, routes.backOfficeAdminAgencies, routes.backOfficeAdminAgency, routes.backOfficeTodo, routes.backOfficeCompaniesDashboard, routes.backOfficeUsers, routes.backOfficeSettings]} component={scenes.backOffice} exact={false} />
          
          {/* Companies */}
          <Route path={routes.root} component={scenes.root} exact={true} />
          <Route path={routes.companyIndex} component={scenes.companyIndex} exact={true} />
          <UserRoute path={routes.selectCompanyFromMail} component={scenes.selectCompanyFromMail} exact={true} />
          <UserRoute path={routes.selectCompany} component={scenes.selectCompany} exact={true} />
          <CompanyRoute path={routes.dashboard} component={scenes.dashboard} exact={true} />
          <CompanyRoute path={routes.onboarding} component={scenes.onboarding} exact={true} />
          
          {/* Services */}
          <CompanyRoute path={routes.accountingServices} component={scenes.accountingServices} exact={true} />
          <CompanyRoute path={routes.submitAgencyInterest} component={scenes.submitAgencyInterest} exact={true} />
          <CompanyRoute path={routes.businessServices} component={scenes.businessServices} exact={true} />
          
          {/* Accounting */}
          <CompanyRoute path={routes.bankTransactions} component={scenes.bankTransactions} exact={true} />
          <CompanyRoute path={routes.assetsManagement} component={scenes.assetsManagement} exact={true} />
          
          {/* Uploads */}
          <CompanyRoute path={routes.uploads} component={scenes.uploads} exact={true} />
          <Route path={routes.downloadAdditionalFile} component={scenes.downloadAdditionalFile} exact={true} />
          <Route path={routes.downloadAdditionalFilesAsZip} component={scenes.downloadAdditionalFilesAsZip} exact={true} />
          
          {/* Integrations */}
          <CompanyRoute path={routes.integrations} component={scenes.integrations} exact={true} />
          <CompanyRoute path={routes.stripe} component={scenes.stripe} exact={true} />
          <Route path={routes.stripeCallback} component={scenes.stripeCallback} exact={true} />
          <Route path={routes.stripeOnboardingMock} component={scenes.stripeOnboardingMock} exact={true} />
          
          {/* CustomerPortal */}
          <Route path={routes.invoicePortal} component={scenes.invoicePortal} exact={true} />
          <Route path={routes.invoicePortalPdf} component={scenes.invoicePortalPdf} exact={true} />
          
          {/* DirectorsPayroll */}
          <CompanyRoute path={routes.employerDirectorSetup} component={scenes.employerDirectorSetup} exact={true} />
          <CompanyRoute path={routes.directorsPayrollList} component={scenes.directorsPayrollList} exact={true} />
          <CompanyRoute path={routes.directorsPayrollDetails} component={scenes.directorsPayrollDetails} exact={true} />
          <CompanyRoute path={routes.addDirector} component={scenes.addDirector} exact={true} />
          
          {/* Sales */}
          <CompanyRoute path={routes.dailyTakings} component={scenes.dailyTakings} exact={true} /></React.Fragment>;
};
