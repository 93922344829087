import * as React from "react";

import Flex from "@bokio/elements/Flex";
import { Link } from "@bokio/elements/Link/Link";
import * as styles from "@bokio/elements/Sorter/sorterHeading.scss";

import Icon from "../Icon/Icon";
import Th from "../Table/Th";

import type { Visibility } from "../Table/SimpleTable";
import type { SorterConfigExtended } from "./Sorter";

interface SorterHeadingProps {
	sortKey: string;
	config: SorterConfigExtended;
	align?: "right" | "left" | "center";
	isFlex?: boolean;
	className?: string;
	shrink?: boolean;
	testId?: string;
	visibility?: Visibility[];
}

class SorterHeading extends React.Component<React.PropsWithChildren<SorterHeadingProps>> {
	render() {
		const { isFlex } = this.props;
		if (isFlex) {
			return this.renderFlex(this.props.children);
		} else {
			return this.renderTableHead(this.props.children);
		}
	}

	renderFlex = (children: React.ReactNode): React.ReactNode => {
		const { sortKey, config, className } = this.props;
		const activeOrderIcon = config.sortOrder == "asc" ? "ascending" : "descending";

		return (
			// Placeholder for when a flexBox sorting header is needed
			<Flex className={className} direction="column">
				<Link style="none" className={styles.th} onClick={config.setSort(sortKey)} testId={this.props.testId}>
					{children} {config.sortProperty === sortKey && <Icon name={activeOrderIcon} />}
				</Link>
			</Flex>
		);
	};

	renderTableHead = (children: React.ReactNode): React.ReactNode => {
		const { sortKey, align, config, className, shrink, visibility } = this.props;
		const activeOrderIcon = config.sortOrder == "asc" ? "ascending" : "descending";

		return (
			<Th className={className} align={align} shrink={shrink} visibility={visibility}>
				<Link style="none" className={styles.th} onClick={config.setSort(sortKey)} testId={this.props.testId}>
					{children} {config.sortProperty === sortKey && <Icon name={activeOrderIcon} />}
				</Link>
			</Th>
		);
	};
}

export default SorterHeading;
