import * as React from "react";

import { GeneralLangFactory } from "@bokio/lang";
import { FieldRuleLevel } from "@bokio/shared/validation/entityValidator";
import classes from "@bokio/utils/classes";

import type { RuleValidationResult } from "@bokio/shared/validation/entityValidator";

import * as styles from "./validation.scss";

interface ValidationProps {
	errors?: RuleValidationResult[];
	testId?: string;
}

const getErrorsInOrder = (errors: RuleValidationResult[]) => {
	const mustFixNow = (errors && errors.filter(e => e.rule.level === FieldRuleLevel.MustFixNow)) || [];
	const canFixLater = (errors && errors.filter(e => e.rule.level === FieldRuleLevel.MustFixBeforeSend)) || [];
	const warnings = (errors && errors.filter(e => e.rule.level === FieldRuleLevel.Warning)) || [];

	return mustFixNow.concat(canFixLater, warnings);
};

const isMustFixError = (error: RuleValidationResult) => {
	const lang = GeneralLangFactory();
	return (
		error.rule.level === FieldRuleLevel.MustFixNow ||
		(error.action === lang.Validation_Action_DoNow_Required && error.rule.level !== FieldRuleLevel.Warning)
	);
};

export const Validation: React.FunctionComponent<ValidationProps> = ({ errors, testId }) =>
	errors ? (
		<React.Fragment>
			{getErrorsInOrder(errors).map((error, index) => (
				<span
					data-testid={testId}
					key={index}
					className={classes(styles, "item", {
						mustFixNow: isMustFixError(error),
						mustFixBeforeSend: error.rule.level === FieldRuleLevel.MustFixBeforeSend && !isMustFixError(error),
						warning: error.rule.level === FieldRuleLevel.Warning,
					})}
				>
					{error.action}
				</span>
			))}
		</React.Fragment>
	) : null;
