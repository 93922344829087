import { v4 as uuidv4 } from "uuid";

import { importPixels } from "@bokio/hooks/usePixels/usePixels";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { getRoute } from "@bokio/shared/route";

import {
	completePurchasePlanValue,
	DV360TrackingEvent,
	GaConversionTrackingEvent,
	getLinkedInPlan,
	LinkedinTrackingEvent,
	trackConversion,
	trackEvent,
	trackEventDV360,
	trackEventFB,
	trackEventLinkedIn,
	trackEventMSFT,
	trackEventTaboolaPixel,
	trackEventTikTokPixel,
	TrackingPlatform,
	trackPageView,
} from "./t";

import CompanyDraftSource = m.Entities.CompanyDraftSource;

/* File to keep our funnel events collected in one place to make it easier to audit their usage.
 *  This should be events we base marketing funnels on. They are also sent to more platforms than most our events
 *
 * It's important that any trackers we add are covered in the cookie policy. https://www.bokio.se/villkor-och-gdpr/cookiepolicy/ and https://www.bokio.co.uk/legal-privacy/cookie-policy/
 * If we only want to track in one country we need to make sure the config handles that
 *
 * Also remember to check the CSP if you add a new tracking vendor so that it allows loading scripts and making calls for the new Vendor
 *
 * Scripts for tracking are loaded in src\web\App.tsx
 */

const canTrack: () => boolean = () => {
	return window && window.doNotTrackStatus === "Track";
};

export function trackfunnel_visitSignup() {
	if (canTrack()) {
		const eventId = uuidv4();
		trackEvent("Signup", "StartRegistration", undefined, undefined, undefined, eventId);
		trackEventFB("ViewContent", { content_name: "StartRegistration" }, { eventID: eventId });
		trackEventMSFT("Signup", "StartRegistration");
		trackEventLinkedIn(LinkedinTrackingEvent.StartRegistration);
		trackEventDV360(DV360TrackingEvent.StartRegistration);
		trackEventTikTokPixel("StartRegistration", { content_id: "StartRegistration" });
	}
	//ME: We don't have this for Verve or ADNXS. No real reason to not add them if we need it
}

//Variant With BankID
export function trackfunnel_usedBankIdSignup() {
	if (canTrack()) {
		trackEvent("Signup", "UsedBankId");
	}
}

//variant No BankID
export function trackfunnel_noBankIdSignup() {
	if (canTrack()) {
		trackEvent("Signup", "NoBankId");
	}
}

export function trackFunnel_usedSearch() {
	if (canTrack()) {
		trackEvent("Signup", "UsedSearch");
	}
}

export function trackFunnel_searchResultsSuccess() {
	if (canTrack()) {
		trackEvent("Signup", "SearchResultsSuccess");
	}
}

export function trackFunnel_noSearchResults() {
	if (canTrack()) {
		trackEvent("Signup", "NoSearchResults");
	}
}

export function trackFunnel_fetchedCompanySuccess() {
	if (canTrack()) {
		trackEvent("Signup", "FetchedCompanySuccess");
	}
}

export function trackFunnel_enterCompanyManual() {
	if (canTrack()) {
		trackEvent("Signup", "EnterCompanyManual");
	}
}

export function trackFunnel_eneterCompanyManualConfirmed() {
	if (canTrack()) {
		trackEvent("Signup", "EnterCompanyManualConfirm");
	}
}

/** This when they confirmed their email and set their password in the signup flow */
export function trackfunnel_completedRegistration() {
	if (canTrack()) {
		const eventId = uuidv4();

		trackEvent("Signup", "CompleteRegistration", undefined, undefined, undefined, eventId);
		trackEventFB("CompleteRegistration", {}, { eventID: eventId });
		trackEventMSFT("Signup", "CompleteRegistration");
		trackEventLinkedIn(LinkedinTrackingEvent.CompleteRegistration);
		trackEventDV360(DV360TrackingEvent.CompletedRegistration);
		trackConversion(GaConversionTrackingEvent.CompletedRegistration, {
			value: 0.0,
			transaction_id: "",
		});
		trackEventTaboolaPixel("signup");
		trackEventTikTokPixel("CompleteRegistration", { content_id: "CompleteRegistration" });
		//ME: We don't have this for Verve or ADNXS. No real reason to not add them if we need it
	}
}

export function trackfunnel_testCompanyCreated() {
	if (canTrack()) {
		const eventId = uuidv4();

		trackEvent("Signup", "StartTestCompany", undefined, undefined, undefined, eventId);
		trackEventFB("Lead", { content_name: "StartTestCompany" }, { eventID: eventId });
		trackEventMSFT("Signup", "StartTestCompany");
		trackEventLinkedIn(LinkedinTrackingEvent.StartTestCompany);

		//ME: We don't have this for Verve or ADNXS. No real reason to not add them if we need it
	}
}

export function trackfunnel_realCompanyCreated() {
	if (canTrack()) {
		const eventId = uuidv4();

		trackEvent("Signup", "StartTrial", undefined, undefined, undefined, eventId);
		trackEventFB("StartTrial", {}, { eventID: eventId });
		trackEventFB("Purchase", {}, { eventID: uuidv4() });
		trackEventMSFT("Signup", "StartTrial");
		trackEventLinkedIn(LinkedinTrackingEvent.StartTrial);
		trackEventDV360(DV360TrackingEvent.StartTrial);
		trackConversion(GaConversionTrackingEvent.StartTrial, {
			value: 0.0,
			transaction_id: "",
		});
		trackEventTaboolaPixel("complete_registration");
		trackEventTikTokPixel("StartTrial", { content_id: "StartTrial" });
		//ME: We don't have this for Verve or ADNXS. No real reason to not add them if we need it
	}
}

/** This is the old events we sent that is the same for both test and real companies. */
export function trackFunnel_draftCompleted_legacy(source: CompanyDraftSource) {
	if (canTrack()) {
		if (source === CompanyDraftSource.SignUp) {
			trackPageView(`${getRoute("signUp", { step: "new-user", subStep: "customize-company" })}/finished`);
		}
		trackPageView(`create-company/finished`);
	}
}

export function trackfunnel_startedPurchasePlan(
	plan: m.Entities.BokioPlan,
	interval: m.Bokio.Common.Billing.Model.SubscriptionInterval,
) {
	if (canTrack()) {
		const eventId = uuidv4();

		trackEvent(
			"BokioPlan",
			"StartConfirmPurchase",
			`Plan_${plan}_Intervall_${interval}`,
			undefined,
			undefined,
			eventId,
		);
		trackEventFB("InitiateCheckout", { content_ids: plan ? [plan] : undefined }, { eventID: eventId });
		trackEventMSFT("BokioPlan", "StartConfirmPurchase", `Plan_${plan}_Intervall_${interval}`);
		trackConversion(GaConversionTrackingEvent.StartedPurchasePlan, {
			value: 0.0,
			transaction_id: "",
		});
		trackEventTikTokPixel("StartConfirmPurchase", {
			content_id: plan ? plan : undefined,
		});
		//JL: Linkedin tracking for purchases not added yet

		//ME: We don't have this for Verve or ADNXS. No real reason to not add them if we need it
	}
}

export function trackfunnel_completedPurchasePlan(
	plan: m.Entities.BokioPlan,
	interval: m.Bokio.Common.Billing.Model.SubscriptionInterval,
	currency: string,
	planPriceExVat?: number,
) {
	if (canTrack()) {
		const { sendVerve } = importPixels();
		const isPremiumPlus = plan === m.Entities.BokioPlan.Pro;
		const priceInterval = `${plan} ${interval}ly`;
		const eventId = uuidv4();

		trackEventFB(
			"Subscribe",
			{
				content_ids: [plan],
				currency: currency,
				value: completePurchasePlanValue(plan, TrackingPlatform.Facebook),
				content_type: priceInterval,
			},
			{ eventID: eventId },
		);
		trackEventMSFT(priceInterval, "Subscribe", {
			revenue_value: completePurchasePlanValue(plan, TrackingPlatform.Microsoft),
			currency: currency,
		});
		trackEvent(
			"BokioPlan",
			"Upgrade",
			priceInterval,
			completePurchasePlanValue(plan, TrackingPlatform.GA4),
			{
				currency: currency,
				subscription_type: priceInterval,
			},
			eventId,
		);

		try {
			trackEventLinkedIn(getLinkedInPlan(plan));
		} catch (error) {}

		trackEventTaboolaPixel("make_purchase", {
			revenue: completePurchasePlanValue(plan, TrackingPlatform.Taboola),
		});
		trackEventTikTokPixel(
			"CompletePurchasePlan",
			{ content_id: plan, content_type: priceInterval },
			planPriceExVat?.toString(),
			currency,
		);
		sendVerve("3163677");
		if (isPremiumPlus) {
			trackConversion(GaConversionTrackingEvent.PremiumPlusPlan, {
				value: completePurchasePlanValue(plan, TrackingPlatform.Gads),
				currency,
				transaction_id: "",
			});
			trackEventDV360(DV360TrackingEvent.PremiumPlusPlan);
		} else {
			trackConversion(GaConversionTrackingEvent.CompletedPurchasePlan, {
				value: completePurchasePlanValue(plan, TrackingPlatform.Gads),
				currency,
				transaction_id: "",
			});
			trackEventDV360(DV360TrackingEvent.CompletedPurchasePlan);
		}
	}
}

export function trackfunnel_bbaStartedActivation(companyType: string, userId: string | undefined) {
	if (canTrack()) {
		const eventId = uuidv4();

		trackEvent(
			"BokioBusinessAccount",
			"Activation",
			"Started",
			0,
			{
				companyType: companyType,
				user_id: userId,
			},
			eventId,
		);
		trackConversion(GaConversionTrackingEvent.BBAStartedActivation, {
			value: 0.0,
			transaction_id: "",
		});
		trackEventFB(
			"BBAStartedActivation",
			{
				value: 0.0,
				currency: "SEK",
			},
			{ eventID: eventId },
			true,
		);
		trackEventMSFT("BBAStartedActivation", "", {
			revenue_value: 0,
			currency: "SEK",
		});
		trackEventDV360(DV360TrackingEvent.BBAStartedActivation);
		trackEventTaboolaPixel("activated_account_bba");
		trackEventLinkedIn(LinkedinTrackingEvent.BBAStartedActivation);
	}
}

export function trackfunnel_bbaAgreementSigned(companyType: string, userId: string | undefined) {
	if (canTrack()) {
		const eventId = uuidv4();

		trackEvent(
			"BokioBusinessAccount",
			"Agreement",
			"Signed",
			0,
			{
				companyType: companyType,
				user_id: userId,
			},
			eventId,
		);
		trackConversion(GaConversionTrackingEvent.BBASignedAgreement, {
			value: 0.0,
			transaction_id: "",
		});
		trackEventFB(
			"BBASignedAgreement",
			{
				value: 0.0,
				currency: "SEK",
			},
			{ eventID: eventId },
			true,
		);
		trackEventMSFT("BBASignedAgreement", "", {
			revenue_value: 0,
			currency: "SEK",
		});
		trackEventDV360(DV360TrackingEvent.BBASignedAgreement);
		trackEventTaboolaPixel("agreement_signed_bba");
		trackEventLinkedIn(LinkedinTrackingEvent.BBASignedAgreement);
	}
}

export function trackfunnel_bbaActivationSubmitted(companyType: string, userId: string | undefined) {
	if (canTrack()) {
		trackEvent("BokioBusinessAccount", "Activation", "Submitted", 0, {
			companyType: companyType,
			user_id: userId,
		});
	}
}

export function trackfunnel_connectEcommerce(
	companyType: string,
	userId: string | undefined,
	ecommerce_platform: string | undefined,
) {
	if (canTrack()) {
		trackEvent("ECommerce", "platform_connected", "ecommerce", 0, {
			companyType: companyType,
			ecommerce_platform: ecommerce_platform,
			user_id: userId,
		});
	}
}

export function trackfunnel_ecommerceSetupComplete(
	companyType: string,
	userId: string | undefined,
	ecommerce_platform: string | undefined,
) {
	if (canTrack()) {
		trackEvent("ECommerce", "completed", "ecommerce", 0, {
			companyType: companyType,
			ecommerce_platform: ecommerce_platform,
			user_id: userId,
		});
	}
}

export function trackFunnel_ClickedCampaignBanner(bannerKey: string) {
	if (canTrack()) {
		trackEvent("Promotion", "overview.bba_campaign_banner.clickedbanner", bannerKey);
	}
}

export function trackFunnel_ClickedRequestCall() {
	if (canTrack()) {
		trackEvent("Support", "settings.billing.clicked_request_call");
	}
}
