/* eslint-disable @typescript-eslint/no-empty-object-type */
import * as React from "react";

type InferConnectHoc<InferredProps> = <OwnProps>(
	component: React.ComponentType<OwnProps & InferredProps>,
) => React.FC<React.PropsWithChildren<OwnProps>>;

/**
 * Create a high-order-component (HoC) which injects props like react-redux connect, can also be used for injecting hook return values into class component.
 * @deprecated You should just use hooks and function components directly.
 */
export const createConnectHoc = <TOwnProps extends {}, TConnectProps extends {}>(connect: () => TConnectProps) =>
	((component: React.ComponentType<TOwnProps & TConnectProps>): React.FC<React.PropsWithChildren<TOwnProps>> => {
		const ConnectedComponent: React.FC<TOwnProps> = ownProps => {
			const connectProps = connect();

			return React.createElement<TOwnProps & TConnectProps>(component, { ...ownProps, ...connectProps });
		};

		return ConnectedComponent;
	}) as unknown as InferConnectHoc<TConnectProps>;
