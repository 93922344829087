export interface ApplicationReference {
	applicationId: string;
	revision: string;
}

export type CountryList = { Name: string; Code: string }[];

export enum SveaInternationalPaymentsPreregistrationTabOptions {
	Applications = "applications",
	Accounts = "accounts",
}
export type SveaInternationalPaymentsPreregistrationTab = `${SveaInternationalPaymentsPreregistrationTabOptions}`;
