import flatten from "lodash-es/flatten";

import { FieldRule, FieldRuleFactory, FieldRuleLevel, FieldRuleType } from "./FieldRuleFactory";

import type { FieldValidationResult, RuleValidationResult } from "./FieldRuleFactory";

export { FieldRule, FieldRuleFactory, FieldRuleLevel, FieldRuleType };
export type { FieldValidationResult, RuleValidationResult };

export const filterByField = (
	fieldName: string,
	allErrors: FieldValidationResult[],
	dirtyField = true,
): FieldValidationResult | undefined => (dirtyField ? allErrors.filter(e => e.key === fieldName)[0] : undefined);

export const mustFixNowErrors = (allErrors: FieldValidationResult[], canFixLater: boolean) =>
	// changes old validation text to must fix now
	flatten(allErrors.map(r => r.errors)).filter(
		e =>
			e.rule.level === FieldRuleLevel.MustFixNow || (!canFixLater && e.rule.level === FieldRuleLevel.MustFixBeforeSend),
	);

export const mustFixBeforeSend = (allErrors: FieldValidationResult[], canFixLater: boolean) =>
	flatten(allErrors.map(r => r.errors)).filter(e => canFixLater && e.rule.level === FieldRuleLevel.MustFixBeforeSend);

export const letTheUserChose = (allErrors: FieldValidationResult[]) =>
	flatten(allErrors.map(r => r.errors)).filter(e => e.rule.level === FieldRuleLevel.Warning);

export const mergeValidations = (a: ValidatorResult, b: ValidatorResult): ValidatorResult => {
	return {
		errors: a.errors.concat(b.errors),
		mustFixNow: a.mustFixNow.concat(b.mustFixNow),
		mustFixBeforeSend: a.mustFixBeforeSend.concat(b.mustFixBeforeSend),
		warnings: a.warnings.concat(b.warnings),
	};
};

export const withKeyOf = <T>(name: keyof T, callback: (name: keyof T) => React.ReactNode) => callback(name);

export abstract class Validator {
	public key?: string;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	static recLookup = (obj: any, path: string): any => {
		if (obj == undefined) {
			return;
		}
		if (!path) {
			return obj;
		}

		const parts = path.split(".");
		if (parts.length === 1) {
			return obj[parts[0]];
		}
		return Validator.recLookup(obj[parts[0]], parts.slice(1).join("."));
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	abstract validate(item: any, canFixLater: boolean, submitClick?: boolean): FieldValidationResult[];
}

export abstract class EntityValidator<T> {
	public prefix?: string;

	abstract getRules(item: T): Validator[];

	constructor(public validateOnSubmit?: boolean) {}

	validate = (item: T, canFixLater = true, submitClicked = false): ValidatorResult => {
		if (!this.validateOnSubmit || submitClicked) {
			const rules = this.getRules(Validator.recLookup(item, this.prefix || "")).map(r => {
				r.key = this.prefix ? `${this.prefix}.${r.key}` : r.key;
				return r;
			});
			const errors = flatten(rules.map(r => r.validate(item, canFixLater, submitClicked))).filter(r => r != null);

			return {
				errors,
				mustFixNow: this.mustFixNowErrors(errors, canFixLater),
				mustFixBeforeSend: this.mustFixBeforeSend(errors, canFixLater),
				warnings: this.letTheUserChose(errors),
			};
		}
		return {
			errors: [],
			mustFixNow: [],
			mustFixBeforeSend: [],
			warnings: [],
		};
	};

	validateField = (key: string, item: T, canFixLater: boolean): FieldValidationResult[] => {
		const rules = this.getRules(item).map(r => {
			r.key = this.prefix ? `${this.prefix}.${r.key}` : r.key;
			return r;
		});
		return flatten(rules.map(r => r.validate(item, canFixLater))).filter(r => r != null && r.key === key);
	};

	filterByField = filterByField;

	filterByTypedField = (fieldName: keyof T, allErrors: FieldValidationResult[], dirtyField = true) =>
		filterByField(fieldName.toString(), allErrors, dirtyField);

	mustFixNowErrors = mustFixNowErrors;

	mustFixBeforeSend = mustFixBeforeSend;

	letTheUserChose = letTheUserChose;

	protected propertyOf(name: keyof T) {
		return name;
	}
}

export interface ValidatorResult {
	errors: FieldValidationResult[];
	mustFixNow: RuleValidationResult[];
	mustFixBeforeSend: RuleValidationResult[];
	warnings: RuleValidationResult[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export class FieldValidator<T = {}> implements Validator {
	constructor(
		public key: string,
		public fields: FieldRule[],
	) {}

	validate = (item: T, canFixLater = true): FieldValidationResult[] => {
		const value = Validator.recLookup(item, this.key); // Add deep access through part1.part2.part3
		const errors = this.fields.map(f => f.validate(value, item, canFixLater)).filter(f => f.isError);
		return errors.length ? [{ key: this.key, errors }] : [];
	};

	addRule(rule: FieldRule) {
		this.fields.push(rule);
	}
}

export class CompositeValidator<T> implements Validator {
	constructor(
		public key: string,
		public validator: EntityValidator<T>,
	) {}

	validate = (item: T, canFixLater: boolean) => {
		this.validator.prefix = this.key;
		return this.validator.validate(item, canFixLater).errors;
	};
}
