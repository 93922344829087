import { IconButton } from "@bokio/designsystem/components/Button";
import { mergeClassNames } from "@bokio/utils/classes";

import type * as React from "react";

import * as styles from "./mobileOverlay.scss";

interface MobileOverlayProps {
	visible: boolean;
	action?: React.ReactNode;
	onClose: (cancel: boolean) => void;
	className?: string;
	children: React.ReactNode;
	heading: string;
}

export const MobileOverlay = ({ visible, action, onClose, className, children, heading }: MobileOverlayProps) => {
	return (
		<div className={mergeClassNames(styles.filter, className, visible && styles.active)}>
			<div className={styles.header}>
				<IconButton icon="cancel" type="button" onClick={() => onClose(true)} />
				<h2 className={styles.heading}>{heading}</h2>
				<span className={styles.action}>{action && action}</span>
			</div>
			<div className={styles.controls}>{children}</div>
		</div>
	);
};
