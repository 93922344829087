import * as React from "react";

import { Button } from "@bokio/designsystem/components/Button";
import { FeedbackGraphic } from "@bokio/elements/Feedback";
import GeneralLangFactory from "@bokio/lang/GeneralLangFactory";

import * as styles from "./helpStyle.scss";

interface HelpMessageStatusProps {
	confirmationMessage: string | React.ReactNode;
	isLoading: boolean;
	subscribeToPrio?: () => void;
	onClose?: () => void;
}

class HelpMessageStatus extends React.Component<HelpMessageStatusProps> {
	render() {
		const lang = GeneralLangFactory();
		return (
			<div className={styles.helpConfirmation}>
				{!this.props.isLoading && (
					<div className={styles.helpConfirmationInner}>
						<FeedbackGraphic type="complete" />
						<p className={styles.header}>{lang.HelpConfirmationHeading}</p>
						<div>{this.props.confirmationMessage}</div>
						<Button margin={["top"]} appearance="secondary" onClick={() => this.props.onClose?.()}>
							{lang.Done}
						</Button>
					</div>
				)}
			</div>
		);
	}
}

export default HelpMessageStatus;
