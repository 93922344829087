import compact from "lodash-es/compact";

import { GeneralLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import {
	EntityValidator,
	FieldRuleFactory,
	FieldRuleLevel,
	FieldValidator,
} from "@bokio/shared/validation/entityValidator";

import type { MenuHelpMessageFormValues } from "./Help/Help";
import type { Validator } from "@bokio/shared/validation/entityValidator";

export enum HelpWindowMode {
	Default = 0,
	Search,
	Article,
	Message,
	MessageStatus,
	RequestCall,
	RequestCallMessageStatus,
}

export class HelpStateValidator extends EntityValidator<MenuHelpMessageFormValues> {
	constructor(public isUserLoggedIn: boolean) {
		super();
	}
	getRules(): Validator[] {
		const generalLang = GeneralLangFactory();
		const factory = new FieldRuleFactory(generalLang);
		return compact([
			new FieldValidator(this.propertyOf("Name"), [factory.Required(generalLang.YourName, FieldRuleLevel.MustFixNow)]),
			new FieldValidator(this.propertyOf("Area"), [
				factory.Required(generalLang.Help_WhatIsYourQuestion, FieldRuleLevel.MustFixNow),
				factory.NotEqualTo(
					generalLang.Help_WhatIsYourQuestion,
					m.Contracts.SupportFormArea.NotSet,
					generalLang.NotSet,
					FieldRuleLevel.MustFixNow,
				),
			]),
			new FieldValidator(this.propertyOf("Type"), [factory.Required(generalLang.Help_Type, FieldRuleLevel.MustFixNow)]),
			this.isUserLoggedIn &&
				new FieldValidator(this.propertyOf("Email"), [
					factory.Required(generalLang.Help_WhatIsYourQuestion, FieldRuleLevel.MustFixNow),
					factory.Email(generalLang.Email, FieldRuleLevel.MustFixNow),
				]),
			new FieldValidator(this.propertyOf("Message"), [
				factory.Required(generalLang.Message, FieldRuleLevel.MustFixNow),
			]),
		]);
	}
}

export function newHelpStateValidator(isLoggedIn: boolean) {
	return new HelpStateValidator(isLoggedIn);
}

export class RequestHelpStateValidator extends EntityValidator<MenuHelpMessageFormValues> {
	constructor(public isUserLoggedIn: boolean) {
		super();
	}
	getRules(): Validator[] {
		const generalLang = GeneralLangFactory();
		const factory = new FieldRuleFactory(generalLang);
		return compact([
			new FieldValidator(this.propertyOf("Name"), [factory.Required(generalLang.YourName, FieldRuleLevel.MustFixNow)]),
			new FieldValidator(this.propertyOf("PhoneNumber"), [
				factory.Required(generalLang.PhoneNumber, FieldRuleLevel.MustFixNow),
				factory.PhoneNumber(generalLang.PhoneNumber, FieldRuleLevel.MustFixNow),
			]),
		]);
	}
}

export function newRequestHelpStateValidator(isLoggedIn: boolean) {
	return new RequestHelpStateValidator(isLoggedIn);
}
